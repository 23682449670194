import React, { useState, useEffect } from 'react';
import { Col, Text, CMSLayout, Row, Grid, TouchField } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { TimeHelper, VarHelper } from 'helpers';
import { useDynamicResponsiveValue } from 'quickly-react';
import { ActivityIndicator } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Select from 'react-select';
import { saveAs } from 'file-saver';
import AdminOrderItem from './AdminOrder.Item';

let XLSX;

const Admin: IScreen = () => {
  const { navigate, route } = useNavFunc();
  // @ts-ignore
  const { limit } = route.params || {};
  const rV = useDynamicResponsiveValue();
  const [isCollapse, setIsCollapse] = useState(false);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const [list, setList] = useState([]);

  const getList = async (p = 1, append = false) => {
    const res = await Store.Api.Order.list({
      page: p
    });
    if (res.data.success) {
      const filteredCtv = res.data.data.list.filter(val => !val.ctv);
      const limitList = !limit ? filteredCtv : filteredCtv.slice(0, limit);
      setList(limitList);
    }
  }
  useEffect(() => {
    getList();
  }, []);

  console.log('list', list);

  const updateOrder = async (id, obj) => {
    await Store.Api.Order.upsert({
      id,
      ...obj,
    });
  }

  return (
    <CMSLayout requireAuthen>
      <Col flex1>
        <Text caption m1 p1>*Lưu ý: trạng thái của đơn hàng sẽ ảnh hưởng đến việc hiển thị của tờ tiền. Nếu từ lúc chưa xử lý đơn cho đến khi đã xong, tờ tiền sẽ <Text bold>không xuất hiện</Text> trên mục tra cứu ngoài trang chủ. Khi đơn bị hủy, tờ tiền sẽ <Text bold>xuất hiện trở lại</Text></Text>
        <Grid m1 p1 alignItems={'flex-start'} xs='50%' md='20%' backgroundColor={'white'} round1 shadow>
          <Col p0 m0>
            <Text bold>Tên khách + Sdt</Text>
          </Col>
          <Col p0 m0>
            <Text bold>Địa chỉ</Text>
          </Col>
          <Row p0 m0 justifyContent={'space-between'}>
            <Text bold>Tờ tiền</Text>
            <TouchField width={30} height={30} borderRadius={15} middle onPress={() => setIsCollapse(v => !v)}>
              <MaterialCommunityIcons name={isCollapse ? "arrow-expand" : "arrow-collapse"} size={16} color={COLOR.FONT} />
            </TouchField>
          </Row>
          <Col p0 m0>
            <Text bold>Tổng tiền đơn</Text>
          </Col>
          <Col p0 m0>
            <Text bold>Trạng thái</Text>
          </Col>
        </Grid>
        {list.map((val, i) => {
          return (
            <AdminOrderItem
              key={val.id}
              data={val}
              i={i}
              isCollapse={isCollapse}
              onChangeItem={(index, id, obj) => {
                const newList = list.slice();
                for (let key in obj) {
                  newList[i][key] = obj[key];
                }
                setList(newList);
                updateOrder(id, obj);
              }}
            />
          )

          // return (
          //   <Grid m1 p1 alignItems={'flex-start'} xs='50%' md='20%' backgroundColor={'white'} round1 key={val.id} shadow>
          //     <Col m0 p0>
          //       <Text caption mb0>{TimeHelper.format(val.createdAt)}</Text>
          //       <Text mb0>{val.name}</Text>
          //       <Text>SDT: {val.sdt}</Text>
          //       {!!val.ctv && (
          //         <Text mt1>Đơn hàng này được bán bởi ctv <Text bold>{val.ctv}</Text></Text>
          //       )}
          //     </Col>
          //     <Col m0 p0>
          //       <Text>{val.address}</Text>
          //       {!!val.note && (<Text mt0>Ghi chú: {val.note}</Text>)}
          //     </Col>
          //     <Col m0 p0>
          //       {!shouldCollapse && val.carts.map((vC, vCI) => (
          //         <Col mb1>
          //           <Text mb0 key={vC.id}>Mã  + Seri: <Text bold>{vC.code} {vC.seri}</Text></Text>
          //           <Text mb0>Mệnh giá:  <Text bold>{vC.displayValue}</Text></Text>
          //           <Text mb0>Viết tắt: <Text bold>{vC.short}</Text></Text>
          //         </Col>
          //       ))}
          //       {val.carts.length > 0 && (
          //         <Row mt1 justifyContent={'space-between'}>
          //           <TouchField onPress={() => exportExcel(val)}>
          //             <Row p0>
          //               <MaterialCommunityIcons name="microsoft-excel" size={16} color={'rgba(0,0,0,0.4)'} />
          //               <Text ml0 caption color={'rgba(0,0,0,0.4)'}>Xuất ra excel</Text>
          //             </Row>
          //           </TouchField>
          //           <TouchField width={30} height={30} borderRadius={15} middle onPress={() => setSingleCollapse(v => {
          //             const newV = Object.assign({ }, v);
          //             newV[i] = !newV[i] ? true : false;
          //             return newV;
          //           })}>
          //             <MaterialCommunityIcons name={shouldCollapse ? "arrow-expand" : "arrow-collapse"} size={16} color={COLOR.FONT} />
          //           </TouchField>
          //         </Row>
          //       )}
          //     </Col>
          //     <Col m0 p0>
          //       <Text mb0>Sản phẩm: {VarHelper.formatMoney(productPrice)} đ</Text>
          //       <Text mb0>Phí ship: {VarHelper.formatMoney(val.shippingFee)} đ</Text>
          //       <Text mb0>Tổng: <Text bold color='red' fontSize={20}>{VarHelper.formatMoney(total)} đ</Text></Text>
          //       <Text mb0>Hình thức thanh toán: {val.paymentMethod}</Text>
          //     </Col>
          //     <Col m0 p0>
          //       <Text mb0>Trạng thái thanh toán</Text>
          //       <Select
          //         options={paymentStatusOptions}
          //         value={paymentStatusOptions.find(item => item.value === val.paymentStatus)}
          //         onChange={newValue => {
          //           const newList = list.slice();
          //           newList[i].paymentStatus = newValue.value;
          //           setList(newList);
          //           updateOrder(newList[i].id, { paymentStatus: newValue.value });
          //         }}
          //         styles={{
          //           control: (style) => {
          //             const value = paymentStatusOptions.find(item => item.value === val.paymentStatus);
          //             const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
          //             return {
          //               ...style,
          //               ...findColorStyle,
          //             }
          //           },
          //           singleValue: (style) => {
          //             const value = paymentStatusOptions.find(item => item.value === val.paymentStatus);
          //             const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
          //             return {
          //               ...style,
          //               ...findColorStyle
          //             }
          //           }
          //         }}
          //         menuPortalTarget={document.body}
          //       />
          //       <Text mt1 mb0>Trạng thái shipping</Text>
          //       <Select
          //         options={shippingStatusOptions}
          //         value={shippingStatusOptions.find(item => item.value === val.shippingStatus)}
          //         onChange={newValue => {
          //           const newList = list.slice();
          //           newList[i].shippingStatus = newValue.value;
          //           setList(newList);
          //           updateOrder(newList[i].id, { shippingStatus: newValue.value });
          //         }}
          //         menuPortalTarget={document.body}
          //         styles={{
          //           control: (style) => {
          //             const value = shippingStatusOptions.find(item => item.value === val.shippingStatus);
          //             const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
          //             return {
          //               ...style,
          //               ...findColorStyle,
          //             }
          //           },
          //           singleValue: (style) => {
          //             const value = shippingStatusOptions.find(item => item.value === val.shippingStatus);
          //             const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
          //             return {
          //               ...style,
          //               ...findColorStyle,
          //             }
          //           }
          //         }}
          //       />
          //     </Col>
          //   </Grid>
          // );
        })}
      </Col>
    </CMSLayout>
  )
};

Admin.routeInfo = {
  title: 'Đơn hàng',
  path: '/admin/orders',
};

export default Admin;