import React, { useState } from 'react';
import { Col, Text, TouchField } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR } from 'const';

export const SideNavBare = ({ title, screen, route, navigate, textWhite, params }) => {
  const [isHover, setIsHover] = useState(false);
  const isActive = route.name === screen;

  const bgColor = isActive ? COLOR.MAIN : (
    isHover ? COLOR.GREY_LIGHT : 'transparent'
  );
  const color = isActive ? (textWhite ? 'yellow' : 'white' ) : (!textWhite ? 'black' : 'white' );

  return (
    <Col
      round2 m1 mh2 p1 onPress={() => navigate(screen, params)}
      backgroundColor={bgColor}
      onMouseEnter={( ) => {
        setIsHover(true);
      }}
      onMouseLeave={( ) => {
        setIsHover(false);
      }}
      borderRadius={20}
    >
      <Text fontSize={14} bold={isActive} color={color}>{title}</Text>
    </Col>
  );
}

const SideNav = ({ title, screen, textWhite = false, params }) => {
  const { navigate, route, navigation } = useNavFunc();
  return (
    <SideNavBare
      title={title}
      screen={screen}
      navigate={navigate}
      route={route}
      textWhite={textWhite}
      params={params}
    />
  )
};

export default SideNav;
