import React, { useState, useEffect } from 'react';
import { Col, Text, CMSLayout, Row, Button, Input, Grid, TouchField, RatioCol, MoneyImage } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { Ionicons } from '@expo/vector-icons';
import Select from 'react-select';
import { TimeHelper, VarHelper } from 'helpers';
import ListGrouped from './AdminMoney.List.Group';

const MoneyList = ({ listQuyUoc }) => {
  const [code, setCode] = useState('');
  const [fullSeri, setFullSeri] = useState('');
  const [ngayThang, setNgayThang] = useState('');
  const [year, setYear] = useState('');
  const [short, setShort] = useState('');
  const [saleStatus, setSaleStatus] = useState<'available' | 'pending-ship' | 'sent-to-customer'>('available');
  const [list, setList] = useState([]);
  const [focusMoneyId, setFocusMoneyId] = useState();
  const [order, setOrder] = useState<any>();
  const [viewMode, setViewMode] = useState<'detail' | 'grouped'>('detail');
  const rV = useDynamicResponsiveValue(['xs', 'md']);

  useEffect(() => {
    if (!fullSeri) return;
    setNgayThang('');
    setYear('');
  }, [fullSeri]);

  useEffect(() => {
    if (!ngayThang || !year) return;
    setFullSeri('');
  }, [ngayThang, year]);

  const moneySaleStatusOptins = [
    { label: 'Có sẵn', value: 'available' },
    { label: 'Đang chờ ship', value: 'pending-ship' },
    { label: 'Đã bán cho khách', value: 'sent-to-customer' },
    { label: 'Không có sẵn', value: 'not-available' },
  ]

  const updateMoney = async (moneyId, obj) => {
    const res = await Store.Api.Money.upsert({
      id: moneyId,
      ...obj,
    })
  }

  const loadData = async () => {
    const res = await Store.Api.Money.list({
      code,
      seri: fullSeri,
      dateAndMonth: ngayThang,
      year,
      short,
      saleStatus,
    });
    if (res.data.success) {
      setList(res.data.data.list);
    }
  };

  const getOrderData = async (orderId) => {
    setOrder(undefined);
    const res = await Store.Api.Order.detail({ id: orderId });
    if (res.data.success) setOrder(res.data.data);
  }

  const toggleFocusMoney = (money) => {
    if (!!focusMoneyId && focusMoneyId === money.id) return setFocusMoneyId(undefined);
    !!money.orderId && getOrderData(money.orderId);
    setFocusMoneyId(money.id);
  }

  const renderSearchForm = ({ title, value, onChange, options = undefined }) => {
    return (
      <Col m0 p0>
        <Text mb1>{title}</Text>
        {!!options ? (
          <Select
            value={options.find(val => val.value === value)}
            options={options}
            styles={{
              valueContainer: (style) => ({ ...style, height: 30, minHeight: 30, fontSize: 12 }),
              indicatorsContainer: (style) => ({ ...style, height: 30, minHeight: 30 }),
              control: (style) => ({ ...style, height: 30, minHeight: 30, borderRadius: 15, boxShadow: 'none' }),
            }}
            onChange={newValue => {
              onChange(newValue.value);
            }}
            menuPortalTarget={document.body}
          />
        ) : (
          <Input
            height={30}
            value={value}
            onChange={onChange}
          />
        )}
      </Col>
    )
  }

  return (
    <Col mt0 round1 bgWhite shadow flex1>
      <Grid p1 xs='50%' md='20%'>
        {renderSearchForm({
          title: 'Mã (2 chữ số)',
          value: code,
          onChange: setCode,
        })}
        {renderSearchForm({
          title: 'Full Seri',
          value: fullSeri,
          onChange: setFullSeri,
        })}
        {renderSearchForm({
          title: 'Ngày tháng (3-4 số đấu seri)',
          value: ngayThang,
          onChange: setNgayThang,
        })}
        {renderSearchForm({
          title: 'Năm (4 số cuối seri)',
          value: year,
          onChange: setYear,
        })}
        {renderSearchForm({
          title: 'Mệnh giá',
          value: short,
          onChange: setShort,
          options: listQuyUoc.map(v => {
            return {
              label: `${v.short} | ${VarHelper.formatMoney(v.displayValue)}`,
              value: v.short,
            }
          })
        })}
        {renderSearchForm({
          title: 'Đã bán?',
          value: saleStatus,
          onChange: setSaleStatus,
          options: [
            { label: 'Chưa bán', value: 'available' },
            { label: 'Trong đơn hàng', value: 'pending-ship' },
            { label: 'Đã bán cho khách', value: 'sent-to-customer' },
            { label: 'Không có sẵn', value: 'not-available' },
          ]
        })}
      </Grid>
      <Grid middle={rV({ xs: false, md: true })} xs='100%' md='any:any:any'>
        <Col m0 ph0>
          <Button
            text='Lấy dữ liệu'
            height={30}
            borderRadius={15}
            onPress={loadData}
            width={rV({ xs: '100%', md: 200 })}
          />
        </Col>
        <Col m0 ph0>
          <Button
            m0
            text='Hiển thị chi tiết'
            outline
            height={30}
            iconLeft={viewMode === 'detail' ? (
              <MaterialCommunityIcons name="checkbox-intermediate" size={20} color={COLOR.FONT} />
            ) : (
              <MaterialCommunityIcons name="checkbox-blank-outline" size={20} color={COLOR.FONT} />
            )}
            borderRadius={15}
            width={rV({ xs: '100%', md: 200 })}
            onPress={() => {
              setViewMode('detail')
            }}
          />
        </Col>
        <Col m0 ph0>
          <Button
            p0 m0
            text='Hiển thị gộp dòng'
            outline
            height={30}
            iconLeft={viewMode !== 'detail' ? (
              <MaterialCommunityIcons name="checkbox-intermediate" size={20} color={COLOR.FONT} />
            ) : (
              <MaterialCommunityIcons name="checkbox-blank-outline" size={20} color={COLOR.FONT} />
            )}
            borderRadius={15}
            width={rV({ xs: '100%', md: 200 })}
            onPress={() => {
              setViewMode('grouped')
            }}
          />
        </Col>


      </Grid>
      <Col p0 m0>
        {viewMode === 'detail' && list.length > 0 && (
          <>
            <Row p1 borderThin justifyContent={'space-between'}>
              <Text bold>Ngày tháng năm</Text>
              <Text bold>Full Seri</Text>
              <Text bold>Loại tiền</Text>
              <Text bold width={30}></Text>
            </Row>
            {list.map((val, i) => {
              return (
                <Col key={val.id}>
                  <Row p1 borderThin justifyContent={'space-between'}>
                    <Text fontSize={16}>{val.code} - {val.dateAndMonth} - {val.year}</Text>
                    <Text>{val.seri}</Text>
                    <Text bold>[ {val.short} ]</Text>
                    <TouchField ml1 width={30} height={30} borderRadius={15} middle
                      onPress={() => toggleFocusMoney(val)}
                    >
                      <Ionicons name="information-circle-outline" size={20} color={COLOR.MAIN} />
                    </TouchField>
                  </Row>
                  {focusMoneyId === val.id && (
                    <Grid xs='100%' md='50%' backgroundColor={COLOR.GREY_BG} alignItems='flex-start'>
                      <Col p0>
                        <RatioCol width={'100%'} ratio={316 / 160} backgroundColor={'rgba(0,0,0,0.3)'}>
                          <Col flex1 middle p1>
                            <MoneyImage
                              {...val}
                            />
                          </Col>
                        </RatioCol>
                      </Col>
                      <Col p2 alignItems={'flex-end'}>
                        <Text>Ngày tạo: {TimeHelper.format(val.createdAt)}</Text>
                        {!!order && (
                          <Col mt1 alignItems={'flex-end'}>
                            <Text mb0>Khách hàng: <Text bold>{order?.name}</Text></Text>
                            <Text mb0>SDT: <Text bold>{order?.sdt}</Text></Text>
                            <Text>Địa chỉ: <Text bold>{order?.address}</Text></Text>
                          </Col>
                        )}
                        {val.saleStatus !== 'available' && (
                          <Text mt1 color='red'>Tờ tiền này sẽ không xuất hiện cho khách hàng tra cứu</Text>
                        )}
                        <Text mt1>Đổi trạng thái</Text>
                        <Select
                          value={moneySaleStatusOptins.find(item => item.value === val.saleStatus)}
                          options={moneySaleStatusOptins}
                          styles={{
                            valueContainer: (style) => ({ ...style, height: 30, minHeight: 30, fontSize: 12 }),
                            indicatorsContainer: (style) => ({ ...style, height: 30, minHeight: 30 }),
                            control: (style) => ({ ...style, height: 30, marginTop: 10, width: 200, minHeight: 30, borderRadius: 15, boxShadow: 'none' }),
                          }}
                          onChange={newValue => {
                            const newList = list.slice();
                            newList[i].saleStatus = newValue.value;
                            setList(newList);
                            updateMoney(val.id, { saleStatus: newValue.value });
                          }}
                          menuPortalTarget={document.body}
                        />
                      </Col>
                    </Grid>
                  )}
                </Col>
              )
            })}
          </>
        )}


        {viewMode === 'grouped' && list.length > 0 && (
          <ListGrouped
            list={list}
          />
        )}
      </Col>

    </Col>
  )
};
export default MoneyList;