import { Col, Text, Button, Input } from 'components';
import { IScreen } from 'type';
import React, { useState } from 'react';
import { COLOR, SCREEN, SITE_INFO } from 'const';
import { SVG } from 'assets';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';

const Login: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { redirect } : any = route.params || {};
  const UserStore = Store.useUserStore();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const rV = useDynamicResponsiveValue(['xs', 'md']);

  const handleLogin = async () => {
    if (!email || !password) return alert('Vui lòng điền email và mật khẩu');
    const res = await Store.Client.Api.User.login({
      email,
      password,
    });
    if (res.data.success && res.data.data?.token) {
      const token = res.data.data?.token;
      Store.Client.setToken(token);
      UserStore.set({
        token,
        user: res.data.data?.publicInfo,
      });
      if (redirect) {
        navigate(redirect);
      } else {
        navigate(SCREEN.Admin);
      }
    } else {
      if (res.data.error?.includes('existed')) {
        return alert('Sai thông tin đăng nhập');
      }
      return alert(res.data.error);
    }
  };

  return (
    <Col flex1 middle backgroundColor={COLOR.GREY_BG}>

      <Col alignSelft='center' width={rV({ xs: '90%', md: 400 })} bgWhite shadow round0 overflow={'hidden'}>
        <Col backgroundColor={COLOR.MAIN} height={120} middle>
          <Text h3 color='white' center>{SITE_INFO.NAME}</Text>
        </Col>
        <Col p2>
          <Text>Xin chào</Text>
          <Input
            mt2
            value={email}
            onChange={setEmail}
            placeholder={"Điền email"}
          />
          <Input
            mt2
            value={password}
            onChange={setPassword}
            placeholder={"Điền mật khẩu"}
            password
            inputProps={{
              onSubmitEditing: handleLogin,
            }}
          />
          <Button
            mt2
            solid
            text='ĐĂNG NHẬP'
            width='100%'
            onPress={handleLogin}
          />
        </Col>
      </Col>

    </Col>
  )
}

Login.routeInfo = {
  title: 'Login',
  path: '/login',
}

export default Login;