import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, CMSLayout, Row, Button, Grid, Input, useUIState, TouchField, RatioCol } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { ActivityIndicator, Image } from 'react-native';
import { VarHelper } from 'helpers';
import Select from 'react-select';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { UploadFile } from 'components/elements/form';

const emptyProduct = {
  short: '',
  seri: '',
  displayValue: '',
  currency: '',
  code: '',
  price: 0,
  commonName: '',
  commonDescription: '',
  commonPrice: '',
  commonQuantity: '',
};

const AdminCollectionsCreate: IScreen = () => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};
  const [{ loading: singleLoading }, setSingleUI] = useUIState();
  const [collection, setCollection] = useState<any>();
  const [moneys, setMoneys] = useState<Array<any>>([]);
  const [listQuyUoc, setListQuyUoc] = useState([]);
  const [showUICommonProduct, setShowUICommonProduct] = useState(false);

  const [singleAddedDone, setSingleAddedDone] = useState(false);
  const [single, setSingle] = useState<typeof emptyProduct>({ ...emptyProduct });
  const listQuyUocOptions = listQuyUoc.map(v => ({ label: v.short, value: v.displayValue, currency: v.currency }));

  const uploadFileRef = useRef<{ showDialog?: Function }>(null)
  const editSingleIndex = useRef(-1);

  const [tempImages, setTempImages] = useState([]);

  const singleAdd = async () => {
    if (!single.short) return alert('Vui lòng chọn mệnh giá');
    if (!single.code) return alert('Vui lòng điền kí tự');
    if (!single.seri) return alert('Vui lòng điền seri');
    if (!single.price) return alert('Vui lòng điền giá bán');
    const year = single.seri.slice(single.seri.length - 4, single.seri.length);
    const dateAndMonth = single.seri.slice(0, single.seri.length - 4);
    if (isNaN(+year) || isNaN(+dateAndMonth)) {
      return alert('Vui lòng kiểm tra lại seri');
    }
    setSingleUI({ loading: true });
    const toBeAddedMoney = {
      short: single.short,
      seri: single.seri,
      displayValue: single.displayValue,
      currency: single.currency,
      code: single.code,
      price: single.price,
      year,
      dateAndMonth,
    };
    delete toBeAddedMoney.price;
    const checkExisted = await Store.Api.Money.list({
      code: toBeAddedMoney.code,
      seri: toBeAddedMoney.seri,
      short: toBeAddedMoney.short,
    });
    if (checkExisted.data.data.list.length > 0) {
      setSingleUI({ loading: false });
      const newMoneys = moneys.slice();
      newMoneys.push({
        ...checkExisted.data.data.list[0],
        price: single.price
      });
      setMoneys(newMoneys);
      setSingle({ ...emptyProduct });
      return;
    }
    const res = await Store.Api.Money.upsert(toBeAddedMoney);
    if (res.data.error) {
      alert(res.data.error);
    } else {
      setSingleAddedDone(true);
      setTimeout(() => {
        setSingleAddedDone(false);
      }, 1000);
      const newMoneys = moneys.slice();
      newMoneys.push({
        ...res.data.data,
        price: single.price,
      });
      setMoneys(newMoneys);
      setSingle({ ...emptyProduct });
    }
    setSingleUI({ loading: false });
  }

  useEffect(() => {
    (async () => {
      const res = await Store.Api.QuyUoc.list({});
      if (res.data.success) {
        setListQuyUoc(res.data.data);
      }
    })();
  }, []);

  const getData = async () => {
    const res = await Store.Api.Collection.detail({ id });
    if (res.data.success) {
      setCollection(res.data.data);
      setMoneys(res.data.data.moneys);
    }
  };

  useEffect(() => {
    if (id === 'new') return;
    getData();
  }, [id]);

  const singleFormProps = (label) => {
    return {
      value: single[label],
      onChange: (newVal) => {
        setSingle({
          ...single,
          [label]: newVal
        })
      }
    }
  }

  const upsert = async () => {
    const obj = {
      id,
      name: collection.name,
      slug: collection.slug || '',
      moneys: moneys,
    };
    if (id === 'new') delete obj.id;
    const res = await Store.Api.Collection.upsert(obj);
    if (res.data.error) return alert(res.data.error);
    alert('Thao tác thành công');
    navigation.reset({
      index: 0,
      routes: [{ name: SCREEN.AdminCollections }],
    });
  }

  return (
    <CMSLayout requireAuthen>
      <Col>
        <Grid xs='100%' md='100%'>
          <Col p1 m1>
            <Text mb1>Tên bộ sưu tập</Text>
            <Input
              value={collection?.name || ''}
              onChange={(v) => {
                setCollection({
                  ...collection,
                  name: v
                })
              }}
              backgroundColor='white'
            />
          </Col>
        </Grid>
      </Col>
      <Row flexWrap={'wrap'} mb2 mh2>
        <Text m0 bold>Thêm 1 tờ tiền:</Text>
        <Text m0>Ký tự:</Text>
        <Input {...singleFormProps('code')} width={150} borderWidth={0} borderRadius={0} borderBottomWidth={1} />
        <Text m0 width={70}>Mệnh giá:</Text>
        {/* <Input width={50} borderWidth={0} borderRadius={0} borderBottomWidth={1} /> */}
        <Select
          value={listQuyUocOptions.find(item => item.label === single.short)}
          options={listQuyUocOptions}
          styles={{
            valueContainer: (style) => ({ ...style, height: 30, minHeight: 30, fontSize: 12 }),
            indicatorsContainer: (style) => ({ ...style, height: 30, minHeight: 30 }),
            control: (style) => ({ ...style, width: 200, margin: 5, height: 30, minHeight: 30, borderRadius: 15, boxShadow: 'none' }),
          }}
          onChange={newValue => {
            console.log('onChange', newValue);
            setSingle({ ...single, short: newValue.label, displayValue: newValue.value, currency: newValue.currency });
          }}
          menuPortalTarget={document.body}
        />
        <Text m0 width={50}>Seri:</Text>
        <Input {...singleFormProps('seri')} width={100} borderWidth={0} borderRadius={0} borderBottomWidth={1} />
        <Text m0 width={50}>Giá:</Text>
        <Input {...singleFormProps('price')} width={100} borderWidth={0} borderRadius={0} borderBottomWidth={1} />
        <Button
          isLoading={singleLoading}
          text={singleAddedDone ? 'Thêm thành công' : 'Thêm'}
          onPress={singleAdd}
          height={30}
          borderRadius={15}
          width={120}
          ml1 mt1
        />
      </Row>
      <Col mb2 p2>
        <Row mb1>
          <Text mr0 bold>hoặc Thêm thông tin sản phẩm chung:</Text>
          <TouchField cirle middle
            onPress={() => setShowUICommonProduct(b => !b)}
          >
            <AntDesign name={showUICommonProduct ? "caretup" : "caretdown"} size={16} color={COLOR.MAIN} />
          </TouchField>
        </Row>

        {showUICommonProduct && (
          <>
            <Row alignItems={'flex-start'}>
              <Col flex1>
                <Text mb0>Tên sản phẩm:</Text>
                <Input mb1 {...singleFormProps('commonName')} borderRadius={0} />
                <Text mb0>Mô tả sản phẩm:</Text>
                <Input mb1 {...singleFormProps('commonDescription')} inputProps={{ multiline: true }} height={200} borderRadius={0} />
                <Text mb0>Giá sản phẩm:</Text>
                <Input mb1 {...singleFormProps('commonPrice')} borderRadius={0} />
                <Text mb0>Số lượng:</Text>
                <Input mb1 {...singleFormProps('commonQuantity')} borderRadius={0} />
              </Col>
              <Col flex1 ml2>
                <Text mb0>Hình ảnh:</Text>
                <Text mb1 caption>(Ảnh đầu sẽ là ảnh đại diện, nên sử dụng ảnh ngang)</Text>

                <Grid xs='100%' md='25%' mb1>
                  {tempImages.map((item, index) => {
                    return (
                      <RatioCol ratio={361 / 181} width={'100%'} key={'item-' + index} m0 p0>
                        <Image source={{ uri: item }} style={{ width: '100%', height: '100%' }} resizeMode='cover' />

                        <TouchField backgroundColor={'red'} width={20} height={20} borderRadius={10} absolute middle top={-10} right={-10}
                          onPress={() => {
                            setTempImages(tempImages.filter((v, i) => i !== index))
                          }}
                        >
                          <Ionicons name="close" size={10} color="white" />
                        </TouchField>
                      </RatioCol>
                    );
                  })}

                </Grid>
                <TouchField width={150} onPress={() => uploadFileRef.current?.showDialog()}>
                  <Row p0 middle flex1>
                    <Ionicons name="add-circle-outline" size={24} color="black" />
                    <Text ml0>Thêm ảnh</Text>
                  </Row>
                </TouchField>
                <UploadFile ref={uploadFileRef} onUploaded={urls => {
                  console.log(urls);
                  setTempImages([
                    ...tempImages,
                    urls[0],
                  ])
                }} />
              </Col>
            </Row>

            <Button
              text={editSingleIndex.current === -1 ? 'Thêm' : 'Sửa'}
              onPress={() => {
                const newMoneys = moneys.slice();
                const index = editSingleIndex.current === -1 ? newMoneys.length : editSingleIndex.current;
                newMoneys[index] = {
                  id: VarHelper.genId(),
                  ...newMoneys[index],
                  commonName: single.commonName,
                  commonDescription: single.commonDescription,
                  commonPrice: single.commonPrice,
                  commonImages: tempImages,
                  commonQuantity: single.commonQuantity,
                };
                setMoneys(newMoneys);
                setSingle({ ...emptyProduct });
                setTempImages([]);
                editSingleIndex.current = -1;
              }}
              height={30}
              borderRadius={15}
              width={120}
              mr1 mt1
            />
          </>
        )}

      </Col>
      <Row m1 p1 backgroundColor={COLOR.MAIN} round1>
        <Col flex1>
          <Text bold colorWhite>Kí tự</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Mệnh giá</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Seri</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Giá bán</Text>
        </Col>
        <Col flex1>

        </Col>
      </Row>
      {moneys.map((val, i) => {
        return val.commonName ? (
          <Col m1 p1 backgroundColor={'rgba(255,255,255,0.8)'} round1 key={'item-'+val.i}>
            <Row mb1>
              <Col flex={2}>
                <Text>{val.commonName}</Text>
              </Col>
              <Col flex1><Text caption color="grey">Số lượng: {Number(val.commonQuantity || 0)}</Text></Col>
              <Col flex1>
                <Text>{VarHelper.formatMoney(val.commonPrice)}</Text>
              </Col>
              <Row flex1>
                <Col
                  onPress={() => {
                    editSingleIndex.current = i;
                    setTempImages(val.commonImages);
                    setSingle({
                      ...emptyProduct,
                      ...val,
                    })
                    setShowUICommonProduct(true);
                    window.scrollTo(0,0);
                  }}
                >
                  <AntDesign name="edit" size={24} color="black" />
                </Col>
                <Col ml1
                  onPress={() => {
                    const shouldDelete = confirm('Bạn có chắc muốn xóa tờ tiền này k?');
                    if (!shouldDelete) return;
                    setMoneys(moneys.filter(v => v.id !== val.id));
                  }}
                >
                  <Ionicons name="md-trash-bin-outline" size={24} color="black" />
                </Col>
              </Row>
            </Row>
          </Col>
        ) : (
          <Col m1 p1 backgroundColor={'rgba(255,255,255,0.8)'} round1 key={val.id}>
            <Row mb1>
              <Col flex1>
                <Text>{val.code}</Text>
              </Col>
              <Col flex1>
                <Text>{val.short}</Text>
              </Col>
              <Col flex1>
                <Text>{val.seri}</Text>
              </Col>
              <Col flex1>
                <Text>{VarHelper.formatMoney(val.price)}</Text>
              </Col>
              <Row flex1>
                {/* <Col>
                  <AntDesign name="edit" size={24} color="black" />
                </Col> */}
                <Col ml1
                  onPress={() => {
                    const shouldDelete = confirm('Bạn có chắc muốn xóa tờ tiền này k?');
                    if (!shouldDelete) return;
                    setMoneys(moneys.filter(v => v.id !== val.id));
                  }}
                >
                  <Ionicons name="md-trash-bin-outline" size={24} color="black" />
                </Col>
              </Row>
            </Row>
          </Col>
        );
      })}
      {Boolean(moneys.length > 0 || id !== 'new') && (
        <Button
          m1
          solid
          text={id === 'new' ? 'Tạo' : 'Lưu'}
          onPress={upsert}
        />
      )}
    </CMSLayout>
  )
};

AdminCollectionsCreate.routeInfo = {
  title: 'Bộ sưu tập',
  path: '/admin/collections/:id',
};

export default AdminCollectionsCreate;