import React, { useState, useEffect } from 'react';
import * as Animatable from 'react-native-animatable';
import { 
  useWindowDimensions,
} from 'react-native';

import { adminNav } from './nav';
import { SideNavBare } from './SideNav';
import { HEADER_LOGIN_HEIGHT } from './CMSLayout';
import { COLOR } from 'const';
import SideNav from './SideNav';

const Drawer = ({ navigate, show, route } : any) => {
  const [removed, setRemoved] = useState(true);
  useEffect(() => {
    if (show) {
      setRemoved(false);
    } else {
      setTimeout(() => {
        setRemoved(true);
      }, 500);
    }
  }, [show]);
  return removed ? null : (
    <Animatable.View
      animation={show ? 'fadeInLeft' : 'fadeOutLeft'}
      duration={500}
      style={{
        // width: width,
        // height: height - HEADER_LOGIN_HEIGHT,
        backgroundColor: '#003846',
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        top: HEADER_LOGIN_HEIGHT,
      }}
    >
      {adminNav.map((val, i) => (
        <SideNav key={val.title} title={val.title} screen={val.screen} textWhite />
      ))}
    </Animatable.View>
  )
};

export default Drawer;