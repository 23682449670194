import create from 'zustand';
import { useEffect, useState } from 'react';
import { TUser } from 'type';
import shallow from 'zustand/shallow'
import { SITE_INFO } from 'const';

type TCartItem = any;

interface ICartStore {
  set: (data : any) => void,
  carts: Array<TCartItem>,
  setCarts: Function,
  setShippingFee: Function,
  setPaymentType: Function,
  shippingFee: number,
  paymentType: 'COD' | 'BANK',
}

type TShippingFeeSettings = {
  shippingFee: number,
  paymentType: 'COD' | 'BANK',
  setShippingFee: Function,
  setPaymentType: Function,
}

let _interval;



export const useCartZustandStore = create<ICartStore>((set, get) => ({
  carts: [],
  setCarts: (valueOrFunc) => {
    if (typeof valueOrFunc === 'function') {
      const newCarts : Array<TCartItem> = valueOrFunc(get().carts);
      set({ carts: newCarts });
      localStorage.setItem('carts', JSON.stringify(newCarts));
      return;
    }
    set({ carts: valueOrFunc });
    localStorage.setItem('carts', JSON.stringify(valueOrFunc));
  },
  set,
  shippingFee: 0,
  setShippingFee: (valueOrFunc) => {
    if (typeof valueOrFunc === 'function') {
      const newValue = valueOrFunc(get().shippingFee);
      set({ shippingFee: newValue });
      return;
    }
    set({ shippingFee: valueOrFunc });
  },
  paymentType: 'COD',
  setPaymentType: (valueOrFunc) => {
    if (typeof valueOrFunc === 'function') {
      const newValue = valueOrFunc(get().paymentType);
      set({ paymentType: newValue });
      return;
    }
    set({ paymentType: valueOrFunc });
  },
}));

export const useCartStore = () : [Array<TCartItem>, Function] => {
  const { carts, setCarts } = useCartZustandStore((s) => ({ carts: s.carts, setCarts: s.setCarts }), shallow);
  useEffect(() => {
    if (carts.length === 0) {
      const savedCarts = localStorage.getItem('carts') || '[]';
      try {
        setCarts(JSON.parse(savedCarts));
      } catch(err) {}
    }
  }, []);
  return [carts, setCarts];
};

export const useShippingStore = () : TShippingFeeSettings => {
  const {
    shippingFee,
    setShippingFee,
    paymentType,
    setPaymentType,
  } = useCartZustandStore((s) => ({
    shippingFee: s.shippingFee,
    setShippingFee: s.setShippingFee,
    paymentType: s.paymentType,
    setPaymentType: s.setPaymentType,
  }), shallow);
  useEffect(() => {
    if (paymentType === 'BANK') {
        setShippingFee(SITE_INFO.SHIPPING_FEE_BANK)
    } else {
        setShippingFee(SITE_INFO.SHIPPING_FEE_COD)
    }
}, [paymentType]);
  return {
    shippingFee,
    setShippingFee,
    paymentType,
    setPaymentType,
  };
};