

class DomHelper {

  loadjscssfile(filename: string, filetype: 'js' | 'css') : Promise<undefined> {
    return new Promise((resolve) => {
      var fileref: HTMLScriptElement | HTMLLinkElement;
      if (filetype == "js") { //if filename is a external JavaScript file
        fileref = document.createElement('script')
        fileref.setAttribute("type", "text/javascript")
        fileref.setAttribute("src", filename)
      } else if (filetype == "css") { //if filename is an external CSS file
        fileref = document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
      }
      fileref.onload = () => {
        resolve(undefined);
      }
      // @ts-ignore
      if (typeof fileref != "undefined")
        document.getElementsByTagName("head")[0].appendChild(fileref)
    })
  }

  detectVisible = (el: HTMLElement | null, { on, off }: { on: Function, off: Function }) => {
    if (!el) return;
    const observer = new window.IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          typeof on === 'function' && on();
        } else {
          typeof off === 'function' && off();
        }
      });
    });
    observer.observe(el);
  }

  iOS() {
    if (typeof window === 'undefined') return false;
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  isSafari = () => {
    if (typeof window === 'undefined') return false;
    // @ts-ignore
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    return isSafari;
  }

  iOSSafari = () => {
    if (typeof window === 'undefined') return false;
    const userAgent = navigator.userAgent;
    return /iP(ad|od|hone)/i.test(userAgent) &&
      /WebKit/i.test(userAgent) &&
      !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent));
  }

  isFirefox = () => {
    if (typeof window === 'undefined') return false;
    // @ts-ignore
    return typeof window.InstallTrigger !== 'undefined';
  }

  isChromeLightHouse = () => {
    if (typeof window === 'undefined') return false;
    // @ts-ignore
    return navigator.userAgent.includes("Lighthouse");
  }

  loadScript = (url: string) => new Promise((resolve, reject) => {
    if (typeof window === 'undefined') return
    var script = document.createElement('script');
    script.onload = function () {
      //do stuff with the script
      resolve(undefined);
    };
    script.onerror = function(e) {
      reject(e);
    }
    script.src = url;
    document.head.appendChild(script);
  });
}

export default new DomHelper();