import Request from '../Request.utils'
export interface IListRequestQuery {}


class QuyUocAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/quy-uoc', 'GET', undefined, query, undefined, );
    return res;
  }
}
export default new QuyUocAPI()