import create from 'zustand';
import { useEffect } from 'react';
import { TUser } from 'type';
import Client from './api-client';


interface IUserStore {
  user: TUser | null,
  token: string,
  // saveUser: (u : TUser) => void,
  set: (data : any) => void,
  onReady: () => Promise<TUser | null>,
}

let _interval;

const useZustandUserStore = create<IUserStore>((set, get) => ({
  user: null,
  token: '',
  _ready: false,
  set,
  // saveUser: (u : TUser) => {
  //   set({ user: u })
  // },
  onReady: () => {
    // @ts-ignore
    if (get()._ready) return Promise.resolve(get().user);
    return new Promise((resolve) => {
      _interval = setInterval(() => {
        // @ts-ignore
        if (get()._ready) {
          clearInterval(_interval);
          resolve(get().user);
        }
      }, 500);
    })
  }
}));

export const useUserStore = () => {
  const userStore = useZustandUserStore();
  useEffect(() => {
    const token = Client.getToken();
    if (!token || token === userStore.token) {
      userStore.set({ _ready: true });
      return;
    }
    (async () => {
      const res = await Client.Api.User.me();
      if (res.data.success) {
        userStore.set({
          token,
          user: res.data.data,
          _ready: true,
        });
        return;
      }
      userStore.set({ _ready: true });
    })();
  }, []);

  return userStore;
}