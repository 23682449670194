import { Col, Text, Header, Input, Row, Button, TouchField, Grid } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, SITE_INFO, COLOR, IS_TIENNAMSINH_NET } from 'const';
import { ActivityIndicator, Image } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { AntDesign, Feather, FontAwesome, Entypo } from '@expo/vector-icons';
import { ASSETS } from 'assets';
import Select from 'react-select';

const SearchForm = ({
  ngaythangnamsinh, setNgaythangnamsinh,
  onTraCuu,
  form1Loading,
  yearPairs, setYearPairs,
  form2Loading,
  onTraCuuCapDoi,
  ctv
}) => {
  const { navigate } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;

  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const circleSize = 60;

  const renderInstruction = () => {
    const steps = [
      'Điền \nngày tháng',
      'Ấn \ntra cứu',
      IS_TIENNAMSINH_NET ? 'Tiền \nsố đẹp' : 'Chọn \ntờ tiền',
      'Đặt hàng',
    ];
    // const color = SITE_INFO.FROM_BRAND_COLOR(100);
    const color = 'rgba(0,0,0,0.1)';
    return (
      <Row justifyContent={'space-between'} mb2>
        {steps.map((val, i) => {
          return (
            <React.Fragment key={'in-' + i}>
              <Col m0 width={circleSize} height={circleSize} borderRadius={circleSize / 2} backgroundColor={color} middle>
                <Text center fontSize={10}>{val}</Text>
              </Col>
              {Boolean(i !== (steps.length - 1) && breakpoint !== 'xs') && (
                <AntDesign name="right" size={30} color={color} />
              )}
            </React.Fragment>
          );
        })}
      </Row>
    )
  }

  return (
    <Col backgroundColor={'white'} shadow round1 width={rV({ xs: '100%', md: '80%' })} maxWidth={500} overflow='hidden'>
      <Col height={140}>
        <Col absoluteFill>
          <Image source={SITE_INFO.COVER ? { uri: SITE_INFO.COVER } : ASSETS.MONEY_BG} style={{ width: '100%', height: '100%' }} resizeMode='cover' />
        </Col>
        {ctv ? (
          <Col absoluteFill backgroundColor={'rgba(0,0,0,0.5)'} middle />
        ) : (
          <Col absoluteFill backgroundColor={'rgba(0,0,0,0.5)'} middle>
            <Text center h4 mb1 pv0 ph1 borderRadius={15} backgroundColor={'white'} color={SITE_INFO.BRAND_COLOR}
              fontSize={SITE_INFO.NAME.length < 50 ? undefined : 13}
            >{SITE_INFO.NAME}</Text>
            <Text center caption color={'white'} >{SITE_INFO.DESCRIPTION}</Text>
            <Row mt0 mb1>
              <a href={SITE_INFO.FB} target='_blank' style={{ textDecorationLine: 'none' }}>
                <FontAwesome name="facebook-official" size={16} color="white" />
                <Text caption color='white' mh0>Facebook</Text>
              </a>
              <Text color='white' mh0>|</Text>
              <a href={'tel:' + SITE_INFO.SDT} style={{ textDecorationLine: 'none' }}>
                <Entypo name="phone" size={16} color="white" />
                <Text caption color='white' mh0>{SITE_INFO.SDT}</Text>
              </a>
              <Text color='white' mh0>|</Text>
              <a href={'https://zalo.me/' + SITE_INFO.SDT.replace(/\s/g, '')} style={{ textDecorationLine: 'none', display: 'flex' }}>
                <Image source={ASSETS.ZALO} style={{ width: 16, height: 16 }} />
                <Text caption color='white' mh0>{SITE_INFO.SDT}</Text>
              </a>
            </Row>
            {!!SITE_INFO.ADDRESS && (
              <Row>
                <Entypo name="location" size={16} color="white" />
                <Col flex1 ml0>
                  <Text center caption color={'white'} numberOfLines={1}>{SITE_INFO.ADDRESS}</Text>
                </Col>
              </Row>
            )}
          </Col>
        )}

        {!!user && (
          <Col absolute top={10} right={10}>
            <TouchField
              bgHovered='rgba(0,0,0,0.5)'
              onPress={() => navigate(SCREEN.Admin)}
            >
              <Col p0 ph1 round1 backgroundColor={'rgba(255,255,255,0.4)'}>
                <Text color='white'>Admin</Text>
              </Col>
            </TouchField>
          </Col>
        )}
      </Col>
      <Col p2>
        {renderInstruction()}

        <Col>
          <Text caption mb1>Tìm seri <Text bold color={SITE_INFO.BRAND_COLOR}>ngày tháng năm sinh</Text></Text>
          <Row mb1>
            <Grid flex1 xs='2:3:2' md='1:.'>
              <Col mr0>
                <Input
                  value={ngaythangnamsinh[0]}
                  onChange={newVal => {
                    const newNgay = ngaythangnamsinh.slice();
                    newNgay[0] = newVal;
                    setNgaythangnamsinh(newNgay);
                  }}
                  placeholder='Ngày'
                  inputProps={{
                    style: {
                      fontSize: rV({ xs: 12, md: 14 }),
                      paddingHorizontal: rV({ xs: 5, md: 15 }),
                    }
                  }}
                />
              </Col>
              <Col mr0>
                <Input
                  value={ngaythangnamsinh[1]}
                  onChange={newVal => {
                    const newNgay = ngaythangnamsinh.slice();
                    newNgay[1] = newVal;
                    setNgaythangnamsinh(newNgay);
                  }}
                  placeholder='Tháng'
                />
                {/* <Select
                  placeholder='Tháng'
                  options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'].map(val => ({ label: val, value: val }))}
                  styles={{
                    valueContainer: (style) => ({ ...style, height: 42, minHeight: 42, fontSize: 12 }),
                    indicatorsContainer: (style) => ({ ...style, height: 42, minHeight: 42 }),
                    control: (style) => ({ ...style, margin: 5, minWidth: 70, height: 42, minHeight: 42, borderRadius: 21, boxShadow: 'none', borderColor: 'rgb(186, 186, 186)' }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    placeholder: style => ({ ...style, color: '#383838', fontSize: rV({ xs: 12, md: 14 }) }),
                    singleValue: style => ({ ...style, color: '#383838', fontSize: rV({ xs: 12, md: 14 }) }),
                  }}
                  value={!!ngaythangnamsinh[1] ? { label: ngaythangnamsinh[1], value: ngaythangnamsinh[1] } : undefined}
                  onChange={newValue => {
                    const newNgay = ngaythangnamsinh.slice();
                    newNgay[1] = newValue.value;
                    setNgaythangnamsinh(newNgay);
                  }}
                  menuPortalTarget={document.body}
                /> */}
              </Col>
              <Col mr0>
                <Input
                  value={ngaythangnamsinh[2]}
                  onChange={newVal => {
                    const newNgay = ngaythangnamsinh.slice();
                    newNgay[2] = newVal;
                    setNgaythangnamsinh(newNgay);
                  }}
                  placeholder='Năm'
                  inputProps={{
                    onSubmitEditing: onTraCuu,
                    style: {
                      fontSize: rV({ xs: 12, md: 14 }),
                      paddingHorizontal: rV({ xs: 7, md: 15 }),
                    }
                  }}
                />
              </Col>
            </Grid>
            <Button
              isLoading={form1Loading}
              text={breakpoint === 'xs' ? '' : 'Tra cứu'}
              iconLeft={(
                <Feather name="search" size={20} color="white" />
              )}
              width={breakpoint === 'xs' ? 40 : 100}
              height={40}
              ml0
              borderRadius={20}
              backgroundColor={SITE_INFO.BRAND_COLOR}
              onPress={onTraCuu}
            />
          </Row>


          <Text caption mb1>Tìm seri năm sinh <Text bold color={SITE_INFO.BRAND_COLOR}>cặp đôi</Text></Text>
          <Row mb1>
            <Col flex1>
              <Input
                value={yearPairs[0]}
                onChange={(newVal) => {
                  setYearPairs([
                    newVal, yearPairs[1],
                  ])
                }}
                placeholder='năm sinh chồng/vợ'
              />
            </Col>
            <Col flex1 ml0>
              <Input
                value={yearPairs[1]}
                onChange={(newVal) => {
                  setYearPairs([
                    yearPairs[0], newVal,
                  ])
                }}
                placeholder='năm sinh chồng/vợ'
                inputProps={{
                  onSubmitEditing: onTraCuuCapDoi,
                }}
              />
            </Col>
            <Button
              isLoading={form2Loading}
              text={breakpoint === 'xs' ? '' : 'Tra cứu'}
              iconLeft={(
                <Feather name="search" size={20} color="white" />
              )}
              width={breakpoint === 'xs' ? 40 : 100}
              height={40}
              ml0
              borderRadius={20}
              backgroundColor={SITE_INFO.BRAND_COLOR}
              onPress={onTraCuuCapDoi}
            />
          </Row>
        </Col>
      </Col>

    </Col>
  )
};
export default SearchForm;