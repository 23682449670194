import React, { useEffect, useRef } from 'react';
import { Col, IColProps } from 'components';

import './ApolloEffect.css';

interface IApolloEffectProps extends IColProps {}

const ApolloEfect = ({ children, ...props }: IApolloEffectProps) => {
  const viewRef = useRef(null);

  // useEffect(() => {
  //   console.log('viewRef.current.setNativeProps', viewRef.current?.setNativeProps);
  //   viewRef.current?.setNativeProps({
  //     className: 'apollo-effect',
  //   });
  // }, []);

  return (
    <Col {...props} onRef={(ref) => (viewRef.current = ref)}>
      <div className='apollo-effect'>
        <div className="content">{children}</div>
      </div>
    </Col>
  );
};

export default ApolloEfect;
