import Request from '../Request.utils'
export interface IListRequestQuery {
  code?: string;
  dateAndMonth?: string;
  displayValue?: string;
  page?: number;
  query?: string;
  saleStatus?: string;
  seri?: string;
  short?: string;
  year?: string;
}
export interface IUpsertRequestBody {
  code?: string;
  currency?: string;
  dateAndMonth?: string;
  displayValue?: string;
  id?: string;
  saleStatus?: string;
  seri?: string;
  short?: string;
  year?: string;
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IBulkCreateRequestBody {
  data?: {
    code?: string;
    currency?: string;
    dateAndMonth?: string;
    displayValue?: string;
    seri?: string;
    short?: string;
    year?: string;
  }[];
}
export interface IBulkRemoveRequestBody {
  data?: {
    code?: string;
    currency?: string;
    dateAndMonth?: string;
    displayValue?: string;
    seri?: string;
    short?: string;
    year?: string;
  }[];
}
export interface ISeedJSONRequestBody {
  moneyUrls?: string[];
  quyUocUrl?: string;
}
export interface IListCapDoiRequestQuery {
  year1?: string;
  year2?: string;
}


class MoneyAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/money', 'GET', undefined, query, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/money', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/money/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/money/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  bulkCreate = async (body: IBulkCreateRequestBody) => {
    const res = await Request.call('/api/money/bulk-create', 'POST', undefined, undefined, body, );
    return res;
  }
  bulkRemove = async (body: IBulkRemoveRequestBody) => {
    const res = await Request.call('/api/money/bulk-remove', 'POST', undefined, undefined, body, );
    return res;
  }
  seedJSON = async (body: ISeedJSONRequestBody) => {
    const res = await Request.call('/api/money/seed-json', 'POST', undefined, undefined, body, );
    return res;
  }
  listCapDoi = async (query: IListCapDoiRequestQuery) => {
    const res = await Request.call('/api/money/cap-doi', 'GET', undefined, query, undefined, );
    return res;
  }
}
export default new MoneyAPI()