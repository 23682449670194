import React, { useState, useEffect } from 'react';
import Store from 'store';
import { Col, Row, Text } from 'components';
import { VarHelper } from 'helpers';
import { COLOR, IS_DARK_BACKGROUND } from 'const';
import { useDynamicResponsiveValue } from 'quickly-react';
import { useNavFunc } from 'navigation';

let tempList = [{ name: 'Tiền năm sinh', url: '/' }];

const HomeHeader = () => {

    const { route } = useNavFunc();
    // console.log('route', route);
    const [list, setList] = useState(tempList.slice());
    const rV = useDynamicResponsiveValue(['xs', 'md']);
    useEffect(() => {
        Store.Api.Collection.list({}).then((res) => {
            if (res.data.success && res.data.data.list) {
                setList([
                    { name: 'Tiền năm sinh', url: '/' },
                    ...res.data.data.list.map(v => ({ name: v.name, url: `/collection/${v.id}/${VarHelper.toSlug(v.name)}` }))
                ])
            }
        });
    }, []);

    if (list.length <= 1) return null;
    const activeColor = IS_DARK_BACKGROUND ? 'yellow' : 'white';
    const normalColor = IS_DARK_BACKGROUND ? 'white' : COLOR.MAIN;
    return (
        <Col middle>
            <Row flexWrap={'wrap'} width={rV({ xs: '100%', md: '50%' })} maxWidth={500} mb2 marginHorizontal={-10} middle>
                {list.map((v, i) => {
                    const isActive = window.location.pathname === v.url;
                    return (
                        <Col m0 key={'nav-'+i} p1 round1 borderWidth={1} borderColor={COLOR.MAIN} backgroundColor={isActive ? COLOR.MAIN : 'transparent'}
                            onPress={() => {
                                window.location.pathname = v.url;
                            }}
                        >
                            <Text color={isActive ? activeColor : normalColor}>{v.name}</Text>
                        </Col>
                    )
                })}
            </Row>
        </Col>
    );
};


export default HomeHeader;