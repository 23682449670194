import React, { useState, useEffect } from 'react';
import { Col, Row, Text } from 'components';
import { COLOR, SCREEN } from 'const';
import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import Store from 'store';
import { VarHelper } from 'helpers';
import SideNav from './SideNav';


let tempList = [{ name: 'Tiền năm sinh', url: '/', screen: SCREEN.Home, params: {} }];

export const Nav = () => {

    const [navs, setNavs] = useState(tempList);
    const updateNavs = (v) => {
        setNavs(v);
        tempList = v;
    }

    useEffect(() => {
        Store.Api.Collection.list({}).then((res) => {
            if (res.data.success && res.data.data.list) {
                setNavs([
                    { name: 'Tiền năm sinh', url: '/', screen: SCREEN.Home, params: {} },
                    ...res.data.data.list.map(v => ({
                        name: v.name,
                        url: `/collection/${v.id}/${VarHelper.toSlug(v.name)}`,
                        screen: SCREEN.CollectionDetail,
                        params: { id: v.id, slug: VarHelper.toSlug(v.name) },
                    }))
                ])
            }
        });
    }, []);

    return (
        <Col borderThin round1 p1 bgWhite shadow mt2>
            <Row mb1 pl1>
                <FontAwesome5 name="money-bill-wave" size={24} color={COLOR.MAIN} />
                <Text ml2 bold color={COLOR.MAIN}>Danh mục sản phẩm</Text>
            </Row>
            {navs.map((v, i) => {
                // const isActive = window.location.pathname === v.url;
                // return (
                //     <Col m0 mb1 key={'nav-'+i} p1 round1 borderWidth={1} borderColor={COLOR.MAIN} backgroundColor={isActive ? COLOR.MAIN : 'transparent'}
                //         onPress={() => {
                //             // window.location.pathname = v.url;
                //         }}
                //     >
                //         <Text color={isActive ? 'white' : COLOR.MAIN}>{v.name}</Text>
                //     </Col>
                // )
                return (
                    <SideNav
                        key={'nav-'+i}
                        title={v.name}
                        screen={v.screen}
                        params={v.params}
                        url={v.url}
                        textWhite={false}
                    />
                )
            })}
        </Col>
    )
};