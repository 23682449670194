import React from 'react';
import { AntDesign, FontAwesome5, EvilIcons, MaterialIcons, Entypo, FontAwesome } from '@expo/vector-icons';
import { Col, Row, Text, TouchField, Button, modal } from 'components';
import { VarHelper } from 'helpers';
import { COLOR } from 'const';
import Store from 'store';
import { SubmitOrder } from './Home.SubmitOrder';

const CartSidebar = ({ ctv, onPlaceOrderDone } : any) => {
    const [carts, setCarts] = Store.useCartStore();
    const {
        shippingFee,
        setShippingFee,
        paymentType,
        setPaymentType,
    } = Store.useShippingStore();
    console.log('carts', carts);
    const totalOrderVND = (!carts || carts.length === 0) ? 0 : shippingFee + carts.reduce((a, b) => ({ price: +a.price + +b.price })).price;
    return (
        <Col borderThin round1 p1 bgWhite shadow>
            <Row mb1 pl1>
                <Entypo name="shopping-cart" size={24} color={COLOR.MAIN} />
                <Text ml2 bold color={COLOR.MAIN}>Giỏ hàng{!totalOrderVND ? '' : <Text ml1 bold color='red'>{VarHelper.formatMoney(totalOrderVND)} vnđ</Text>}</Text>
            </Row>
            {carts.length === 0 ? (
                <Text caption ml1>Bạn chưa có sản phẩm nào trong giỏ hàng</Text>
            ) : (
                <>
                    {carts.map((val, i) => {
                        return (
                            <Row key={val.id} mb1 p1 borderBottomWidth={i !== carts.length - 1 ? 0.5 : 0} borderBottomColor={COLOR.GREY}>
                                <Col flex1 ml0>
                                    {val.commonName ? (
                                        <Text>{val.quantity || 1} x {val.commonName}</Text>
                                    ) : (
                                        <Text>{val.code} {val.seri} | {VarHelper.formatMoney(val.displayValue)}</Text>
                                    )}
                                    <Text bold color='red'>{VarHelper.formatMoney(val.price)} vnđ</Text>
                                </Col>
                                <TouchField
                                    cirle middle
                                    onPress={() => {
                                        setCarts(carts.filter(item => item.id !== val.id));
                                    }}
                                >
                                    <MaterialIcons name="delete-forever" size={18} color={COLOR.GREY} />
                                </TouchField>
                            </Row>
                        )
                    })}
                    <Col
                        m1
                    >
                        <Button
                            text='Kiểm tra đơn hàng'
                            borderWidth={0.5}
                            width='100%'
                            height={30}
                            borderRadius={15}
                            fontStyle={{
                                color: 'red',
                                fontWeight: 'bold',
                            }}
                            solid
                            onPress={() => {
                                modal.show(
                                    <SubmitOrder
                                        onPlaceOrderDone={onPlaceOrderDone}
                                        ctv={ctv}
                                        setPaymentType={setPaymentType}
                                    />
                                )
                            }}
                        />
                    </Col>
                </>
            )}
        </Col>
    );
}

export default CartSidebar;