import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Header, Input, Row, Button, useUIState, Grid, MoneyImage, RatioCol, ApolloEffect, modal, TouchField, HomeHeader } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, SITE_INFO, COLOR, IS_TIENNAMSINH_NET } from 'const';
import { ActivityIndicator, Image, ScrollView } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { AntDesign, FontAwesome5, EvilIcons, MaterialIcons, Entypo, FontAwesome } from '@expo/vector-icons';
import { ASSETS } from 'assets';
import { FacebookProvider, Page, CustomChat } from 'react-facebook';
import SearchForm from './Home.SearchForm';
import { SubmitOrder } from './Home.SubmitOrder';
import { TimeHelper, VarHelper } from 'helpers';
import Swal from 'sweetalert2'

const Cart = ({ onPlaceOrderDone, ctv }) => {

    const [carts, setCarts] = Store.useCartStore();
    const {
        shippingFee,
        setShippingFee,
        paymentType,
        setPaymentType,
    } = Store.useShippingStore();

    const rV = useDynamicResponsiveValue(['xs', 'md']);
    const breakpoint = rV({ xs: 'xs', md: 'md' });

    useEffect(() => {
        if (paymentType === 'BANK') {
            setShippingFee(SITE_INFO.SHIPPING_FEE_BANK)
        } else {
            setShippingFee(SITE_INFO.SHIPPING_FEE_COD)
        }
    }, [paymentType]);
    console.log(carts);
    const totalOrderVND = carts.length === 0 ? 0 : shippingFee + carts.reduce((a, b) => ({ price: +a.price + +b.price })).price;

    return carts.length === 0 ? null : (
        <Grid xs='100%' md='any:any'
            shadow
            bottom={0} left={0} right={0}
            backgroundColor='white'
            borderTopWidth={2}
            borderTopColor={SITE_INFO.BRAND_COLOR}
            middle
            // @ts-ignore
            position='fixed'
        >
            <Row middle p0>
                <Entypo name="shopping-cart" size={24} color={SITE_INFO.BRAND_COLOR} />
                <Text center ml2>Đã chọn: <Text bold>{carts.length}</Text></Text>
                <Text ml2>Tổng:</Text>
                <Text ml2 color='red' bold>{VarHelper.formatMoney(totalOrderVND)}</Text>
            </Row>
            <Row middle p0>
                <Button
                    outline
                    borderColor={'red'}
                    text='Kiểm tra đơn hàng'
                    width={200}
                    height={30}
                    borderRadius={15}
                    fontStyle={{
                        color: 'red',
                        fontWeight: 'bold',
                    }}
                    bgHovered={'rgba(255, 0, 0, 0.2)'}
                    ml2
                    onPress={() => {
                        modal.show(
                            <SubmitOrder
                                // carts={carts}
                                // setCarts={setCarts}
                                onPlaceOrderDone={onPlaceOrderDone}
                                ctv={ctv}
                                setPaymentType={setPaymentType}
                            />
                        )
                    }}
                />
            </Row>
        </Grid>
    )
};

export default Cart;