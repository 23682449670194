import Request from './Request.utils';
import * as Api from './api';

class PrintManagerClient {

  setHost(v: string) {
    Request.setHost(v);
  }
  setToken(v: string) {
    Request.setToken(v);
  }
  getToken = () => Request.getToken();

  Api=Api
}

export default new PrintManagerClient();