import React, { useState } from 'react';
import { Col, Text, TouchField } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR } from 'const';

export const SideNavBare = ({ title, screen, route, navigate, textWhite, params, url }) => {
  const [isHover, setIsHover] = useState(false);
  const isActive = window.location.pathname === url;

  const bgColor = (
    isHover ? COLOR.GREY_LIGHT : 'transparent'
  );
  const color = isActive ? (textWhite ? 'yellow' : COLOR.MAIN ) : (!textWhite ? 'black' : COLOR.MAIN );

  return (
    <Col
      round2 m1 p1 mv0 onPress={() => navigate(screen, params)}
      backgroundColor={bgColor}
      onMouseEnter={( ) => {
        setIsHover(true);
      }}
      onMouseLeave={( ) => {
        setIsHover(false);
      }}
      borderRadius={20}
    >
      <Text
        fontSize={14} bold={isActive} color={color}
      >{title}</Text>
      {isActive && (
        <Col absolute top={13} left={-5} borderRadius={20} width={10} height={10} backgroundColor={COLOR.MAIN}>

        </Col>
      )}
      
    </Col>
  );
}

const SideNav = ({ title, screen, textWhite = false, params, url }) => {
  const { navigate, route, navigation } = useNavFunc();
  console.log('route', route, screen);
  return (
    <SideNavBare
      title={title}
      screen={screen}
      navigate={navigate}
      route={route}
      textWhite={textWhite}
      params={params}
      url={url}
    />
  )
};

export default SideNav;
