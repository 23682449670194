import React, { useState, useEffect, useMemo } from 'react';
import { Col, Text, CMSLayout, Row, Button, Input, SwapIconEffect, useUIState, Grid } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import Select from 'react-select';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { AntDesign } from '@expo/vector-icons';

const BulkRemove = ({ listQuyUoc }) => {
  const [single, setSingle] = useState({
    short: '',
    seri: '',
    displayValue: '',
    currency: '',
    code: '',
  });
  const [{ loading: singleLoading }, setSingleUI] = useUIState();
  const [{ loading: multiLoading }, setMultiUI] = useUIState();
  const [singleAddedDone, setSingleAddedDone] = useState(false);
  const [multiText, setMultiText] = useState('');

  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const smallColumnWidth = rV({ xs: 50, md: 100 });

  const previewGroupedMoney = useMemo(() => {
    const results = [];
    const lines = multiText.split('\n').filter(val => !!val);
    console.log('lines', lines);
    const indexRef = {}; // { [dateCodeId] }

    const getYearObjectFromString = (str) => {
      if (!str.includes('(')) {
        // 1101995
        const year = str.slice(str.length - 4, str.length);
        return { [year]: true };
      }
      const yearCode = str.slice(str.indexOf('(') + 1, str.indexOf(')'));
      // 1950 -> 2020
      // 1950 -> 2020, +2030, -2000
      const obj = {};
      const parts = yearCode.split(',').map(val => val.trim());
      parts.forEach(val => {
        if (val.includes('->')) {
          const [from, to] = val.split('->').map(val => val.trim());
          for (let i = +from; i <= +to; i++) {
            obj[String(i)] = true;
          }
        }
        if (val.includes('+')) {
          obj[val.replace('+', '')] = true;
        }
        if (val.includes('-')) {
          obj[val.replace('-', '')] = false;
        }
      });
      return obj;
    }

    lines.forEach((line, i) => {
      // line: BA 1 1101995
      // line: BA 1 110(1950 -> 2020)
      // line: BA 1 110(1950 -> 2020, +2030, -2000)
      let parts = line.trim().split(' ');
      if (parts.length < 3) return;
      console.log(parts.slice(2, parts.length).join(' '));
      parts = [
        parts[0],
        parts[1],
        parts.slice(2, parts.length).join(' '),
      ];
      if (!!parts[2].match(/[A-Za-z]/)) return;
      const dateAndMonth = !parts[2].includes('(') ? parts[2].slice(0, parts[2].length - 4) : parts[2].slice(0, parts[2].indexOf('('));
      console.log('dateAndMonth', dateAndMonth);
      const dateCodeId = `${dateAndMonth}_${parts[1]}_${parts[0]}`;
      let currentIndex = indexRef[dateCodeId];

      // generate year
      const years = getYearObjectFromString(parts[2]);
      console.log('getYearObjectFromString', years);

      if (currentIndex === undefined) {
        results.push({
          dateCodeId,
          dateAndMonth: dateAndMonth,
          short: parts[1],
          code: parts[0],
          years,
        })
        currentIndex = results.length - 1;
        indexRef[dateCodeId] = currentIndex;
      } else {
        results[currentIndex].years = {
          ...results[currentIndex].years,
          ...years,
        }
      }
    });


    return results.map(v => {
      const filterYears = {};
      for (let y in v.years) {
        if (v.years[y]) filterYears[y] = true;
      }
      return { ...v, years: filterYears };
    });
  }, [multiText]);

  console.log('previewGroupedMoney', previewGroupedMoney);

  const singleAdd = async () => {
    if (!single.short) return alert('Vui lòng chọn mệnh giá');
    if (!single.code) return alert('Vui lòng điền kí tự');
    if (!single.seri) return alert('Vui lòng điền seri');
    const year = single.seri.slice(single.seri.length - 4, single.seri.length);
    const dateAndMonth = single.seri.slice(0, single.seri.length - 4);
    if (isNaN(+year) || isNaN(+dateAndMonth)) {
      return alert('Vui lòng kiểm tra lại seri');
    }
    setSingleUI({ loading: true });
    const toBeAddedMoney = {
      ...single,
      year,
      dateAndMonth,
    }
    const checkExisted = await Store.Api.Money.list({
      code: toBeAddedMoney.code,
      seri: toBeAddedMoney.seri,
      short: toBeAddedMoney.short,
    });
    if (checkExisted.data.data.list.length > 0) {
      setSingleUI({ loading: false });
      return alert('Tờ tiền này đã được thêm vào từ trước');
    }
    const res = await Store.Api.Money.upsert(toBeAddedMoney);
    if (res.data.error) {
      alert(res.data.error);
    } else {
      setSingleAddedDone(true);
      setTimeout(() => {
        setSingleAddedDone(false);
      }, 1000);
    }
    setSingleUI({ loading: false });
  }

  const singleFormProps = (label) => {
    return {
      value: single[label],
      onChange: (newVal) => {
        setSingle({
          ...single,
          [label]: newVal
        })
      }
    }
  }

  const removeFromGroupedMoney = (val, year) => {
    const lines = multiText.split('\n').filter(val => !!val);
    const findLineIndex = lines.findIndex(l => l.includes(`${val.code} ${val.short} ${val.dateAndMonth}`));
    if (findLineIndex === -1) return;
    if (!lines[findLineIndex].includes(')')) {
      lines.push(`${val.code} ${val.short} ${val.dateAndMonth}(-${year})`)
    } else {
      lines[findLineIndex] = lines[findLineIndex].replace(')', `, -${year})`);
    }
    setMultiText(lines.join('\n'));
  };

  const multiRemove = async () => {
    if (previewGroupedMoney.length === 0) {
      return alert('Vui lòng kiểm tra lại phần nhập liệu');
    }
    let arr = [];
    previewGroupedMoney.forEach((group) => {
      const findShort = listQuyUoc.find(val => val.short === group.short);
      if (!findShort) {
        console.error('can not find short', group);
        return;
      }

      const groupArr = Object.keys(group.years).map((year) => ({
        code: group.code,
        dateAndMonth: group.dateAndMonth,
        short: group.short,
        year: year,
        seri: `${group.dateAndMonth}${year}`,
        displayValue: findShort.displayValue,
        currency: findShort.currency,
      }));

      arr = [...arr, ...groupArr];
    });
    setMultiUI({ loading: true });
    const res = await Store.Api.Money.bulkRemove({
      data: arr,
    })
    if (res.data.error) {
      alert(res.data.error)
    } else {
      alert('Xóa hàng loạt thành công');
    }
    setMultiUI({ loading: false });
    setMultiText('');
  };


  const listQuyUocOptions = listQuyUoc.map(v => ({ label: v.short, value: v.displayValue, currency: v.currency }));

  const h = (t) => <Text padding={5} backgroundColor='#ddd'>{t}</Text>

  return (
    <Col mt0 p2 round1 bgWhite shadow flex1>
      <Row flexWrap={'wrap'} mb2>
        <Text color="red" bold>LƯU Ý: Cẩn trọng khi sử dụng mục này, dữ liệu bị xóa nhầm không lấy lại được!!</Text>
      </Row>
      <Col>
        <Grid xs='100%' md='50%' alignItems={'flex-start'} marginHorizontal={-15}>
          <Col m1 p1>
            <Text mb1 bold>Chọn nhiều tờ tiền 1 lúc:</Text>
            <Text caption mb1>* Thêm nhiều tờ tiền 1 lúc sẽ cần viết theo quy tắc</Text>
            <Text caption mb1 lineHeight={30}>* Cấu trúc: {h(`[ký tự] [mệnh giá viết tắt] [seri]`)}. VD: {h(`BA 1 1101995`)}</Text>
            <Text caption mb1 lineHeight={30}>* Sử dựng {h(`(năm -> năm)`)} để nhập nhiều năm 1 lúc. VD: {h(`BA 1 110(1950 -> 2020)`)}</Text>
            <Text caption mb1 lineHeight={30}>* Sử dựng {h(`+năm`)} hoặc {h(`-năm`)} để loại trừ 1 số năm. VD: {h(`BA 1 110(1950 -> 2020, +2030, -2000)`)}</Text>
            <Text caption mb1 lineHeight={30}>* Với cấu trúc trên, viết xuống dòng để nhập thêm tờ khác. Không để trên cùng 1 dòng.</Text>
          </Col>
          <Col m1 p1>
            <Input
              height={200}
              value={multiText}
              onChange={setMultiText}
              borderRadius={5}
              backgroundColor={COLOR.GREY_BG}
              inputProps={{
                multiline: true,
                style: {
                  fontWeight: 'bold',
                }
              }}
            />
          </Col>
        </Grid>
        {previewGroupedMoney.length > 0 && (
          <Col mt2>
            <Row p1 borderThin justifyContent={'space-between'}>
              <Col width={smallColumnWidth}>
                <Text bold>Ngày</Text>
              </Col>
              <Col width={smallColumnWidth}>
                <Text bold>MG</Text>
              </Col>
              <Col width={smallColumnWidth}>
                <Text bold>Ký tự</Text>
              </Col>
              <Row flex1 justifyContent={'space-between'}>
                <Text bold>Năm</Text>
              </Row>
            </Row>
            {previewGroupedMoney.map((val, i) => {
              const years = (() => {
                return Object.keys(val.years).sort();
              })();
              if (years.length === 0) return null;
              return (
                <Row key={val.dateCodeId} p1 borderThin justifyContent={'space-between'}>
                  <Col width={smallColumnWidth}>
                    <Text bold>{val.dateAndMonth}</Text>
                  </Col>
                  <Col width={smallColumnWidth}>
                    <Text bold>{val.short}</Text>
                  </Col>
                  <Col width={smallColumnWidth}>
                    <Text bold>{val.code}</Text>
                  </Col>
                  <Row flex1 flexWrap={'wrap'}>
                    {years.map((y, yI) => {
                      return (
                        <Row middle width={60} borderRadius={10} p0 m0 key={val.dateCodeId + '_' + y} backgroundColor={'#ddd'}>
                          <Text mr0>{y}</Text>
                          <AntDesign onPress={() => removeFromGroupedMoney(val, y)} name="close" size={10} color="red" />
                        </Row>
                      );
                      return null;
                    })}
                  </Row>
                </Row>
              )
            })}

            <Text caption mt2>* Quá trình thêm hàng loạt sẽ tốn chút thời gian. Vui lòng bấm nút rồi đợt trong giây lát</Text>
            <Button
              isLoading={multiLoading}
              mt1
              text='Xóa hàng loạt'
              onPress={multiRemove}
              height={30}
              borderRadius={15}
              width={150}
            />
          </Col>
        )}
      </Col>
    </Col>
  )
};
export default BulkRemove;