import Request from '../Request.utils'
export interface IListRequestQuery {
  name?: string;
  page?: number;
  paymentMethod?: string;
  paymentStatus?: string;
  sdt?: string;
  shippingStatus?: string;
}
export interface IUpsertRequestBody {
  address?: string;
  carts?: any;
  ctv?: string;
  id?: string;
  name?: string;
  note?: string;
  paymentMethod?: string;
  paymentStatus?: string;
  sdt?: string;
  shippingFee?: number;
  shippingStatus?: string;
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IListCTVRequestQuery {
  ctv?: string;
  name?: string;
  page?: number;
  paymentMethod?: string;
  paymentStatus?: string;
  sdt?: string;
  shippingStatus?: string;
}


class OrderAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/orders', 'GET', undefined, query, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/order', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/orders/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/orders/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  listCTV = async (query: IListCTVRequestQuery) => {
    const res = await Request.call('/api/orders/list-ctv', 'GET', undefined, query, undefined, );
    return res;
  }
}
export default new OrderAPI()