import React, { useState, useEffect } from 'react';
import { Col, Text, CMSLayout, Row, Grid, TouchField } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { TimeHelper, VarHelper } from 'helpers';
import { useDynamicResponsiveValue } from 'quickly-react';
import { ActivityIndicator } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Select from 'react-select';
import { saveAs } from 'file-saver';

let XLSX;
const shippingStatusOptions = [
  { label: 'Chưa xử lý', value: 'pending' },
  { label: 'Đã gửi shipper', value: 'sent-to-shipper' },
  { label: 'Hủy đơn', value: 'cancel' },
];
const paymentStatusOptions = [
  { label: 'Chưa nhận được tiền', value: 'not-receive' },
  { label: 'Đã nhận được tiền', value: 'did-receive' },
  { label: 'Không rõ', value: 'not-sure' },
];
const STATUS_COLOR = {
  'not-receive': {
    backgroundColor: '#EB890A',
    color: 'white',
  },
  'did-receive': {
    backgroundColor: 'green',
    color: 'white',
  },
  'pending': {
    backgroundColor: '#5AD539',
    color: 'black',
  },
  'cancel': {
    backgroundColor: 'red',
    color: 'white',
  },
  'sent-to-shipper': {
    backgroundColor: '#0183FF',
    color: 'white',
  }
}

const AdminOrderItem = ({ data: val, i, isCollapse, onChangeItem }) => {

  const [singleCollapse, setSingleCollapse] = useState(false);

  useEffect(() => {
    setSingleCollapse(isCollapse);
  }, [isCollapse]);

  useEffect(() => {
    if (!XLSX) {
      import('xlsx').then(xlsx => {
        XLSX = xlsx;
      });
    }
  }, []);

  const productPrice = val.carts?.length === 0 ? 0 : val.carts?.reduce((a, b) => ({ price: +a.price + +b.price })).price
  const total = val.carts?.length === 0 ? 0 : +val.shippingFee + productPrice;

  const exportExcel = (order) => {
    const { carts, name, sdt } = order;
    var wb = XLSX.utils.book_new();

    const withDayAndMonthSeparated = carts.map((val) => {
      const getMonthDate = (dam) => {
        if (dam.length === 4) return {
          day: dam.slice(0, 2),
          month: dam.slice(2, 4),
        };
        if (dam.length === 3) {
          const s01 = dam.slice(0, 1);
          const s02 = dam.slice(0, 2);
          const s13 = dam.slice(1, 3);
          const s23 = dam.slice(2, 3);
          if (s01 === '0') {
            return {
              day: +s02,
              month: +s23,
            }
          }
          if (s13.indexOf('0') === 0 || s13 > 12) {
            if (+s02 > 31) return {
              day: +s01,
              month: +s13,
            }
            return {
              day: +s02,
              month: +s23,
            }
          } else if (s13 <= 12) {
            return {
              day: +s13,
              month: +s01,
            }
          }
        }
        return {
          day: 0,
          month: 0,
        }
      };
      return {
        ...val,
        ...getMonthDate(val.dateAndMonth),
      }
    })

    const sortedCarts = withDayAndMonthSeparated.sort((a, b) => {
      return a.month !== b.month ?
        (a.month > b.month ? 1 : -1)
        : (a.day > b.day ? 1 : -1);
    });

    var ws = XLSX.utils.json_to_sheet(sortedCarts.map(val => ({
      'Mã': val.code,
      'Mệnh giá': val.displayValue,
      'Full Seri': val.seri,
      'Viết tắt': val.short,
      'Ngày và tháng': val.dateAndMonth,
      'Ngày': val.day,
      'Tháng': val.month,
      'Năm': val.year,
    })));
    XLSX.utils.book_append_sheet(wb, ws, "book");
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `đơn_hàng_${name}_${sdt}.xlsx`);
  };

  return (
    <Grid m1 p1 alignItems={'flex-start'} xs='50%' md='20%' backgroundColor={'white'} round1 key={val.id} shadow>
      <Col m0 p0>
        <Text caption mb0>{TimeHelper.format(val.createdAt)}</Text>
        <Text mb0>{val.name}</Text>
        <Text>SDT: {val.sdt}</Text>
        {!!val.ctv && (
          <Text mt1>Đơn hàng này được bán bởi ctv <Text bold>{val.ctv}</Text></Text>
        )}
      </Col>
      <Col m0 p0>
        <Text>{val.address}</Text>
        {!!val.note && (<Text mt0>Ghi chú: {val.note}</Text>)}
      </Col>
      <Col m0 p0>
        {val.carts.length > 0 && (
          <Row mb1 justifyContent={'space-between'}>
            <TouchField onPress={() => exportExcel(val)}>
              <Row p0>
                <MaterialCommunityIcons name="microsoft-excel" size={16} color={'rgba(0,0,0,0.4)'} />
                <Text ml0 caption color={'rgba(0,0,0,0.4)'}>Xuất ra excel</Text>
              </Row>
            </TouchField>
            <TouchField width={30} height={30} borderRadius={15} middle onPress={() => setSingleCollapse(v => !v)}>
              <MaterialCommunityIcons name={singleCollapse ? "arrow-expand" : "arrow-collapse"} size={16} color={COLOR.FONT} />
            </TouchField>
          </Row>
        )}
        {!singleCollapse && val.carts.map((vC, vCI) => (
          <Col mb1>
            {(vC.source && vC.source === 'khotiennamsinh.com') && (
              <Row mb1>
                <Col padding4 borderRadius={4} borderWidth={1} borderColor="red">
                  <Text color="red">khotiennamsinh.com</Text>
                </Col>
              </Row>
            )}
            {vC.commonName ? (
              <>
                <Text mb0 key={vC.id}>Tên SP: <Text bold>{vC.commonName}</Text></Text>
                <Text mb0 key={vC.id}>Số lượng: <Text bold>{vC.quantity}</Text></Text>
              </>
            ) : (
              <>
                <Text mb0 key={vC.id}>Mã  + Seri: <Text bold>{vC.code} {vC.seri}</Text></Text>
                <Text mb0>Mệnh giá:  <Text bold>{vC.displayValue}</Text></Text>
                <Text mb0>Viết tắt: <Text bold>{vC.short}</Text></Text>
              </>
            )}
            
          </Col>
        ))}
      </Col>
      <Col m0 p0>
        <Text mb0>Sản phẩm: {VarHelper.formatMoney(productPrice)} đ</Text>
        <Text mb0>Phí ship: {VarHelper.formatMoney(val.shippingFee)} đ</Text>
        <Text mb0>Tổng: <Text bold color='red' fontSize={20}>{VarHelper.formatMoney(total)} đ</Text></Text>
        <Text mb0>Hình thức thanh toán: {val.paymentMethod}</Text>
      </Col>
      <Col m0 p0>
        <Text mb0>Trạng thái thanh toán</Text>
        <Select
          options={paymentStatusOptions}
          value={paymentStatusOptions.find(item => item.value === val.paymentStatus)}
          onChange={newValue => {
            // const newList = list.slice();
            // newList[i].paymentStatus = newValue.value;
            // setList(newList);
            // updateOrder(newList[i].id, { paymentStatus: newValue.value });
            onChangeItem(i, val.id, {
              paymentStatus: newValue.value
            })
          }}
          styles={{
            control: (style) => {
              const value = paymentStatusOptions.find(item => item.value === val.paymentStatus);
              const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
              return {
                ...style,
                ...findColorStyle,
              }
            },
            singleValue: (style) => {
              const value = paymentStatusOptions.find(item => item.value === val.paymentStatus);
              const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
              return {
                ...style,
                ...findColorStyle
              }
            }
          }}
          menuPortalTarget={document.body}
        />
        <Text mt1 mb0>Trạng thái shipping</Text>
        <Select
          options={shippingStatusOptions}
          value={shippingStatusOptions.find(item => item.value === val.shippingStatus)}
          onChange={newValue => {
            // const newList = list.slice();
            // newList[i].shippingStatus = newValue.value;
            // setList(newList);
            // updateOrder(newList[i].id, { shippingStatus: newValue.value });
            onChangeItem(i, val.id, {
              shippingStatus: newValue.value
            })
          }}
          menuPortalTarget={document.body}
          styles={{
            control: (style) => {
              const value = shippingStatusOptions.find(item => item.value === val.shippingStatus);
              const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
              return {
                ...style,
                ...findColorStyle,
              }
            },
            singleValue: (style) => {
              const value = shippingStatusOptions.find(item => item.value === val.shippingStatus);
              const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
              return {
                ...style,
                ...findColorStyle,
              }
            }
          }}
        />
      </Col>
    </Grid>
  );
};

export default AdminOrderItem;