import React from 'react';
import classnames from 'classnames';
import './ImageRatio.css';

const ImageRatio = (props) => {
  return (
    <div
      className={classnames("blf-img-container", props.className)}
      style={{
        paddingTop: `${100 / props.ratio}%`,
        backgroundImage: `url("${props.src}")`,
      }}>
    </div>
  );
};

export default ImageRatio;
