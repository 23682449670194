import React from 'react';
import { IScreen } from 'type';


import Home from './Home';

const Embed: IScreen = () => {

  return (
    <Home />
  );
}

Embed.routeInfo = {
  title: 'Tiền ngày tháng năm sinh',
  path: '/embed/:ctv',
}

export default Embed;