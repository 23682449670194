import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import RequestUtils from 'store/api-client/Request.utils';
import Store from 'store';

interface IProps {
  isMulti?: boolean,
  onUploaded(urls: Array<string>): void,
}

const UploadFile = forwardRef((props : IProps, ref) => {

  const inputRef = useRef(null);

  const clearFiles = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
    inputRef.current.type = '';
    inputRef.current.type = 'file';
  }

  const onChange : React.InputHTMLAttributes<HTMLInputElement>['onChange'] = async e => {
    const files = Array.from(e.target.files);
    console.log('onChange files', files);
    if (files.length === 0) return props.onUploaded([]);
    
    const toBeProcessedFiles = props.isMulti ? files : [files[0]];

    const promiseArr = toBeProcessedFiles.map(async (val : File) => {
      try {
        const res1 = await RequestUtils.upload('/api/users/upload', val);
        return RequestUtils.h(res1.data.data);
      } catch(err){
        return undefined;
      }
    });

    const urls = await Promise.all(promiseArr);
    props.onUploaded(urls.filter(val => !!val));
    clearFiles();
  }

  useImperativeHandle(ref, () => ({

    showDialog: () => {
      console.log('showDialog', inputRef.current);
      inputRef.current?.click();
    },

  }));

  return (
    <input multiple ref={inputRef} type="file" style={{ display: 'none' }} onChange={onChange} />
  );
});

export default UploadFile;