import React, { useState, useEffect } from 'react';
import { Col, Text, CMSLayout } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

const Admin: IScreen = () => {
  const { navigate } = useNavFunc();

  return (
    <CMSLayout requireAuthen>
      <Col flex1>
        
      </Col>
    </CMSLayout>
  )
};

Admin.routeInfo = {
  title: 'Admin',
  path: '/admin',
};

export default Admin;