import React, { useState, useEffect } from 'react';
import { Col, Text, CMSLayout, Row } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';
import { VarHelper } from 'helpers';

const Admin: IScreen = () => {
  const { navigate } = useNavFunc();
  const [list, setList] = useState([]);

  const getData = async () => {
    const res = await Store.Api.QuyUoc.list({});
    if (res.data.success) {
      setList(res.data.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <CMSLayout requireAuthen>
      <Row m1 p1 backgroundColor={COLOR.MAIN} round1>
        <Col flex1>
          <Text bold colorWhite>Viết tắt</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Mệnh giá</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Giá bán</Text>
        </Col>
        <Col flex1>
          <Text bold numberOfLines={1} colorWhite>Giá cặp đôi</Text>
        </Col>
        <Col flex1>
          <Text bold numberOfLines={1} colorWhite>Giá cặp đôi đặc biệt</Text>
        </Col>
      </Row>
      {list.map((val, i) => {
        return (
          <Col m1 p1 backgroundColor={i % 2 ===0 ? 'rgba(255,255,255,0.8)' : '#ddd'} round1 key={val.id}>
            <Row mb1>
              <Col flex1>
                <Text>{val.short}</Text>
              </Col>
              <Col flex1>
                <Text>{VarHelper.formatMoney(val.displayValue)}</Text>
              </Col>
              <Col flex1>
                <Text>{VarHelper.formatMoney(val.price)}</Text>
              </Col>
              <Col flex1>
                <Text>{VarHelper.formatMoney(val.priceYearPair)}</Text>
              </Col>
              <Col flex1>
                <Text >{VarHelper.formatMoney(val.priceYearPairSpecial)}</Text>
              </Col>
            </Row>
          </Col>
        );
      })}
    </CMSLayout>
  )
};

Admin.routeInfo = {
  title: 'Quy Ước tiền',
  path: '/admin/quy-uoc',
};

export default Admin;