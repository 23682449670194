import React, { useState, useEffect } from 'react';
import { Col, Text, CMSLayout, Row, Grid, TouchField } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { VarHelper, TimeHelper } from 'helpers';
import { useDynamicResponsiveValue } from 'quickly-react';
import { ActivityIndicator } from 'react-native';
import Select from 'react-select';
import { saveAs } from 'file-saver';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const shippingStatusOptions = [
  { label: 'Chưa xử lý', value: 'pending' },
  { label: 'Đã gửi shipper', value: 'sent-to-shipper' },
  { label: 'Hủy đơn', value: 'cancel' },
];
const paymentStatusOptions = [
  { label: 'Chưa nhận được tiền', value: 'not-receive' },
  { label: 'Đã nhận được tiền', value: 'did-receive' },
  { label: 'Không rõ', value: 'not-sure' },
];
const STATUS_COLOR = {
  'not-receive': {
    backgroundColor: '#EB890A',
    color: 'white',
  },
  'did-receive': {
    backgroundColor: 'green',
    color: 'white',
  },
  'pending': {
    backgroundColor: '#5AD539',
    color: 'black',
  },
  'cancel': {
    backgroundColor: 'red',
    color: 'white',
  },
  'sent-to-shipper': {
    backgroundColor: '#0183FF',
    color: 'white',
  }
}

let XLSX;

const Admin: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const rV = useDynamicResponsiveValue();
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const { ctv } : any = route.params || {};

  const [list, setList] = useState([]);

  useEffect(() => {
    if (!XLSX) {
      import('xlsx').then(xlsx => {
        XLSX = xlsx;
      });
    }
  }, []);

  const getList = async (p = 1, append = false) => {
    const res = await Store.Api.Order.listCTV({
      page: p,
      ctv,
    });
    if (res.data.success) {
      setList(res.data.data.list);
    }
  }
  useEffect(() => {
    getList();
  }, []);

  const exportExcel = (order) => {
    const { carts, name, sdt } = order;
    var wb = XLSX.utils.book_new();

    const withDayAndMonthSeparated = carts.map((val) => {
      const getMonthDate = (dam) => {
        if (dam.length === 4) return {
          day: dam.slice(0, 2),
          month: dam.slice(2, 4),
        };
        if (dam.length === 3) {
          const s01 = dam.slice(0, 1);
          const s02 = dam.slice(0, 2);
          const s13 = dam.slice(1, 3);
          const s23 = dam.slice(2, 3);
          if (s01 === '0') {
            return {
              day: +s02,
              month: +s23,
            }
          }
          if (s13.indexOf('0') === 0 || s13 > 12) {
            if (+s02 > 31) return {
              day: +s01,
              month: +s13,
            }
            return {
              day: +s02,
              month: +s23,
            }
          } else if (s13 <= 12) {
            return {
              day: +s13,
              month: +s01,
            }
          }
        }
        return {
          day: 0,
          month: 0,
        }
      };
      return {
        ...val,
        ...getMonthDate(val.dateAndMonth),
      }
    })

    const sortedCarts = withDayAndMonthSeparated.sort((a, b) => {
      return  a.month !== b.month ?
        (a.month > b.month ? 1 : -1)
      : (a.day > b.day ? 1 : -1);
    });

    var ws = XLSX.utils.json_to_sheet(sortedCarts.map(val => ({
      'Mã': val.code,
      'Mệnh giá': val.displayValue,
      'Full Seri': val.seri,
      'Viết tắt': val.short,
      'Ngày và tháng': val.dateAndMonth,
      'Ngày': val.day,
      'Tháng': val.month,
      'Năm': val.year,
    })));
    XLSX.utils.book_append_sheet(wb, ws, "book");
    var wbout = XLSX.write(wb, {bookType:'xlsx', type:'array'});
    saveAs(new Blob([wbout],{type:"application/octet-stream"}), `đơn_hàng_${name}_${sdt}.xlsx`);
  };

  return (
    <Col flex1>
      <Text m1>Những đơn hàng này được bán bởi ctv <Text bold>{ctv}</Text></Text>
      <Grid m1 p1 alignItems={'flex-start'} xs='50%' md='20%' backgroundColor={'white'} round1 shadow>
        <Col p0 m0>
          <Text bold>Tên khách + Sdt</Text>
        </Col>
        <Col p0 m0>
          <Text bold>Địa chỉ</Text>
        </Col>
        <Col p0 m0>
          <Text bold>Tờ tiền</Text>
        </Col>
        <Col p0 m0>
          <Text bold>Tổng tiền đơn</Text>
        </Col>
        <Col p0 m0>
          <Text bold>Trạng thái</Text>
        </Col>
      </Grid>
      {list.map((val, i) => {
        const productPrice = val.carts?.length === 0 ? 0 : val.carts?.reduce((a, b) => ({ price: a.price + b.price })).price
        const total = val.carts?.length === 0 ? 0 : +val.shippingFee + productPrice;
        return (
          <Grid m1 p1 alignItems={'flex-start'} xs='50%' md='20%' backgroundColor={'white'} round1 key={val.id} shadow>
            <Col m0 p0>
              <Text caption mb0>{TimeHelper.format(val.createdAt)}</Text>
              <Text mb0>{val.name}</Text>
              <Text>SDT: {val.sdt}</Text>
            </Col>
            <Col m0 p0>
              <Text>{val.address}</Text>
              {!!val.note && (<Text mt0>Ghi chú: {val.note}</Text>)}
            </Col>
            <Col m0 p0>
              {val.carts.map((vC, vCI) => (
                <Col mb1>
                  <Text mb0 key={vC.id}>Mã  + Seri: <Text bold>{vC.code} {vC.seri}</Text></Text>
                  <Text mb0>Mệnh giá:  <Text bold>{vC.displayValue}</Text></Text>
                  <Text mb0>Viết tắt: <Text bold>{vC.short}</Text></Text>
                </Col>
              ))}
              {val.carts.length > 0 && (
                  <Row mt1>
                    <TouchField onPress={() => exportExcel(val)}>
                      <Row p0>
                        <MaterialCommunityIcons name="microsoft-excel" size={16} color={'rgba(0,0,0,0.4)'} />
                        <Text ml0 caption color={'rgba(0,0,0,0.4)'}>Xuất ra excel</Text>
                      </Row>
                    </TouchField>
                  </Row>
                )}
            </Col>
            <Col m0 p0>
              <Text mb0>Sản phẩm: {VarHelper.formatMoney(productPrice)} đ</Text>
              <Text mb0>Phí ship: {VarHelper.formatMoney(val.shippingFee)} đ</Text>
              <Text mb0>Tổng: <Text bold color='red' fontSize={20}>{VarHelper.formatMoney(total)} đ</Text></Text>
              <Text mb0>Hình thức thanh toán: {val.paymentMethod}</Text>
            </Col>
            <Col m0 p0>
              <Text mb0>Trạng thái thanh toán</Text>
              <Select
                options={paymentStatusOptions}
                value={paymentStatusOptions.find(item => item.value === val.paymentStatus)}
                onChange={newValue => {}}
                isDisabled
                styles={{
                  control: (style) => {
                    const value = paymentStatusOptions.find(item => item.value === val.paymentStatus);
                    const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
                    return {
                      ...style,
                      ...findColorStyle,
                    }
                  },
                  singleValue: (style) => {
                    const value = paymentStatusOptions.find(item => item.value === val.paymentStatus);
                    const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
                    return {
                      ...style,
                      ...findColorStyle
                    }
                  }
                }}
                menuPortalTarget={document.body}
              />
              <Text mt1 mb0>Trạng thái shipping</Text>
              <Select
                options={shippingStatusOptions}
                value={shippingStatusOptions.find(item => item.value === val.shippingStatus)}
                onChange={newValue => {}}
                menuPortalTarget={document.body}
                isDisabled
                styles={{
                  control: (style) => {
                    const value = shippingStatusOptions.find(item => item.value === val.shippingStatus);
                    const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
                    return {
                      ...style,
                      ...findColorStyle,
                    }
                  },
                  singleValue: (style) => {
                    const value = shippingStatusOptions.find(item => item.value === val.shippingStatus);
                    const findColorStyle = !!value && !!STATUS_COLOR[value.value] ? STATUS_COLOR[value.value] : {};
                    return {
                      ...style,
                      ...findColorStyle,
                    }
                  }
                }}
              />
            </Col>
          </Grid>
        );
      })}
    </Col>
  )
};

Admin.routeInfo = {
  title: 'Đơn hàng Cộng tác viên',
  path: '/ctv/orders/:ctv',
};

export default Admin;