import React, { useState, useEffect } from 'react';
import { Col, Text, CMSLayout, Row, Button, Input, Grid } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';
import { VarHelper } from 'helpers';
import { AntDesign, Ionicons } from '@expo/vector-icons';

const AdminCollection: IScreen = () => {
  const { navigation } = useNavFunc();
  const [list, setList] = useState([]);

  const getData = async () => {
    const res = await Store.Api.Collection.list({});
    if (res.data.success) {
      console.log('es.data.data.list', res.data.data.list);
      setList(res.data.data.list);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <CMSLayout requireAuthen>
      <Row m1 mb0>
        <Button
          text={'Tạo bộ sưu tập'}
          outline={true}
          height={30}
          borderRadius={15}
          width={120}
          onPress={() => {
            navigation.reset({
              index: 0,
              routes: [{ name: SCREEN.AdminCollectionCreate, params: { id: 'new' } }],
            });
          }}
          bgHovered={COLOR.GREY_BG}
        />
      </Row>
      <Row m1 p1 backgroundColor={COLOR.MAIN} round1>
        <Col flex1>
          <Text bold colorWhite>Tên bộ sưu tập</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Số lượng tờ tiền</Text>
        </Col>
        <Col flex1>
          <Text bold colorWhite>Đã bán</Text>
        </Col>
      </Row>
      {list.map((val, i) => {
        return (
          <Col m1 p1 backgroundColor={'rgba(255,255,255,0.8)'} round1 key={val.id}
            onPress={() => {
              navigation.reset({
                index: 0,
                routes: [{ name: SCREEN.AdminCollectionCreate, params: { id: val.id } }],
              });
            }}
          >
            <Row mb1>
              <Col flex1>
                <Text>{val.name}</Text>
              </Col>
              <Col flex1>
                <Text>{val.moneys?.length}</Text>
              </Col>
              <Row flex1 justifyContent={'space-between'}>
                <Text></Text>
                <Col ml1
                  onPress={async () => {
                    const shouldDelete = confirm('Bạn có chắc muốn xóa bộ sưu tập này k?');
                    if (!shouldDelete) return;
                    const res = await Store.Api.Collection.delete({ id: val.id });
                    if (res.data.success) window.location.reload();
                    else alert(res.data.error);
                  }}
                >
                  <Ionicons name="md-trash-bin-outline" size={24} color="black" />
                </Col>
              </Row>
            </Row>
          </Col>
        );
      })}
    </CMSLayout>
  )
};

AdminCollection.routeInfo = {
  title: 'Bộ sưu tập',
  path: '/admin/collections',
};

export default AdminCollection;