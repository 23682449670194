import React, { useMemo, useState } from 'react';
import { Col, Row, Text, Input } from 'components';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR } from 'const';

const ListGrouped = ({ list }) => {
  const [filterYear, setFilterYear] = useState('');
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });
  const smallColumnWidth = rV({ xs: 50, md: 100 });

  const groupedList = useMemo(() => {
    const results = [];
    const indexRef = {}; // { [dateCodeId] }
    for (let i = 0; i < list.length; i++) {
      const money = list[i];
      const dateCodeId = `${money.dateAndMonth}_${money.short}_${money.code}`;
      let currentIndex = indexRef[dateCodeId];
      if (currentIndex === undefined) {
        results.push({
          dateCodeId,
          dateAndMonth: money.dateAndMonth,
          short: money.short,
          code: money.code,
          years: {
            [money.year]: money.id,
          },
        })
        currentIndex = results.length - 1;
        indexRef[dateCodeId] = currentIndex;
      } else {
        results[currentIndex].years[money.year] = money.id;
      }
    }
    return results;
  }, [list]);

  return (
    <Col>
      <Row p1 borderThin justifyContent={'space-between'}>
        <Col width={smallColumnWidth}>
          <Text bold>Ngày</Text>
        </Col>
        <Col width={smallColumnWidth}>
          <Text bold>MG</Text>
        </Col>
        <Col width={smallColumnWidth}>
          <Text bold>Ký tự</Text>
        </Col>
        <Row flex1 justifyContent={'space-between'}>
          <Text bold>Năm</Text>
          <Input
            height={30} borderWidth={0} placeholder={rV({ xs: 'Lọc', md: 'Lọc năm' })} borderBottomWidth={0.5} borderRadius={0}
            width={rV({ xs: 70, md: 200 })}
            value={filterYear}
            onChange={setFilterYear}
          />
        </Row>
      </Row>
      {groupedList.map((val, i) => {
        const years = (() => {
          const arr = Object.keys(val.years).sort()
          if (!filterYear) return arr;
          return arr.filter(item => filterYear.includes(item));
        })();
        if (years.length === 0) return null;
        return (
          <Row key={val.dateCodeId} p1 borderThin justifyContent={'space-between'}>
            <Col width={smallColumnWidth}>
              <Text bold>{val.dateAndMonth}</Text>
            </Col>
            <Col width={smallColumnWidth}>
              <Text bold>{val.short}</Text>
            </Col>
            <Col width={smallColumnWidth}>
              <Text bold>{val.code}</Text>
            </Col>
            <Row flex1 flexWrap={'wrap'}>
              {years.map((y, yI) => {
                return (
                  <Col borderRadius={10} p0 m0 key={val.dateCodeId + '_' + y} backgroundColor={'#ddd'}>
                    <Text>{y}</Text>
                  </Col>
                );
                return null;
              })}              
            </Row>
          </Row>
        )
      })}
    </Col>
  );
};

export default ListGrouped;