import { Col, Text, MoneyImage } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';
import qs from 'qs';

const SampleMoney : IScreen = ( ) => {

  const getParamFromUrlQuery = () => {
    let obj = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (obj.name) {
      // let index = window.location.search.indexOf('name='+obj.name);
      // let str = window.location.search.slice(index, window.location.search.length);
      let regexp = /&name=(.*)&font=/;
      const match = window.location.search.match(regexp);
      if (!!match && !!match[1]) {
        obj.name = match[1];
      }
    }
    return obj;
  }

  console.log(getParamFromUrlQuery());
    const { seri, code, displayValue, currency } = getParamFromUrlQuery()
    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        <MoneyImage
          seri={seri}
          code={code}
          displayValue={displayValue}
          currency={currency}
        />
      </div>
    )
};

SampleMoney.routeInfo = {
  title: 'Sample Money',
  path: '/sample-money',
};

export default SampleMoney;