import React from 'react';
import { Col, IColProps } from 'components';
import { TextInput, TextInputProps } from 'react-native';
import { FONT } from 'const';

interface IProps extends IColProps {
  value?: any;
  onChange?(newValue: any): void;
  inputProps?: TextInputProps;
  placeholder?: string;
  password?: boolean;
}

const Input = ({ value, onChange, inputProps, placeholder, password, ...props }: IProps) => {
  return (
    <Col borderRadius={20} borderThin height={40} overflow='hidden' {...props}>
      <TextInput
        placeholder={placeholder}
        secureTextEntry={password}
        value={value}
        {...inputProps}
        style={[
          {
            width: '100%',
            padding: 15,
            fontFamily: FONT.defaultFont,
            color: '#383838',
            overflow: 'hidden',
            height: '100%',
            // @ts-ignore
            outline: 'none',
          },
          inputProps?.style,
        ]}
        onChangeText={onChange}
      />
    </Col>
  );
};

export default Input;
