import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Header, Input, Row, Button, useUIState, Grid, MoneyImage, RatioCol, ApolloEffect, modal, TouchField, HomeHeader, HomeLayout } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, SITE_INFO, COLOR, IS_TIENNAMSINH_NET } from 'const';
import { ActivityIndicator, Image, ScrollView } from 'react-native';
import { useDynamicResponsiveValue } from 'quickly-react';
import { AntDesign, FontAwesome5, EvilIcons, MaterialIcons, Entypo, FontAwesome } from '@expo/vector-icons';
import { ASSETS } from 'assets';
import { FacebookProvider, Page, CustomChat } from 'react-facebook';
import SearchForm from './Home.SearchForm';
import { SubmitOrder } from './Home.SubmitOrder';
import { TimeHelper, VarHelper } from 'helpers';
import Swal from 'sweetalert2'
import Cart from './Home.Cart';

const showError = (message) => {
  Swal.fire({
    title: 'Có lỗi xảy ra!',
    text: message,
    icon: 'error',
    confirmButtonText: 'OK'
  })
}

const showAlert = message => {
  Swal.fire(
    'Thành công!',
    message,
    'success'
  )
}

const MoneyDetail = (props: any) => {
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  return (
    <Col bgWhite round1 shadow p2 width={rV({ xs: 360, md: window.innerWidth * 0.8 })}>
      {props.commonName ? (
        <ScrollView contentContainerStyle={{ padding: 10 }}>
          <Text bold mb2>{props.commonName}</Text>
          <Text mb1>{props.commonDescription}</Text>
          <Text mb2>Giá: {VarHelper.formatMoney(props.commonPrice)}</Text>
          <Grid xs='100%' md='100%' mb1 marginHorizontal={-10}>
            {props.commonImages.map((item, index) => {
              return (
                <RatioCol ratio={361 / 181} width={'100%'} key={'item-' + index} m0 p0>
                  <Image source={{ uri: item }} style={{ width: '100%', height: '100%' }} resizeMode='cover' />
                </RatioCol>
              );
            })}

          </Grid>
        </ScrollView>
      ) : (
        <MoneyImage {...props} />
      )}
    </Col>
  )
}

const CollectionDetail: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { id, focus }: any = route.params || {};
  const [listSearch, setListSearch] = useState([]);
  const [carts, setCarts] = Store.useCartStore();
  const [message, setMessage] = useState('');

  const listQuyUoc = useRef([]);
  const rV = useDynamicResponsiveValue(['xs', 'md']);
  const breakpoint = rV({ xs: 'xs', md: 'md' });

  const [{ loading: form1Loading }, setForm1UI] = useUIState();
  const [{ loading: form2Loading }, setForm2UI] = useUIState();

  useEffect(() => {
    if (!id) return;
    Store.Api.Collection.detail({ id })
      .then((res => {
        if (res.data.success) {
          setListSearch(res.data.data.moneys);
        }
      }))
  }, [id]);

  useEffect(() => {
    if (!focus) return;
    const findMoney = listSearch.find(v => v.id === focus);
    if (!findMoney) return;
    showMoneyModal({
      ...findMoney,
      prices: findMoney.price || findMoney.commonPrice,
    })
  }, [listSearch, focus])

  useEffect(() => {
    (async () => {
      const res = await Store.Api.QuyUoc.list({});
      if (res.data.data) {
        listQuyUoc.current = res.data.data;
      }
    })();
  }, []);

  const showMoneyModal = (val) => {
    modal.show(
      <MoneyDetail {...val} />
    )
  };

  const withDisplayOrder = (list) => {
    const seriSorted = list.sort((a, b) => a.seri > b.seri ? 1 : -1);
    if (!SITE_INFO.MONEY_DISPLAY_ORDER) return seriSorted;

    const parts = SITE_INFO.MONEY_DISPLAY_ORDER.split(',').map(v => v.trim());
    let results = [];
    let excludeIndexs = [];
    const higherPriorityArr = parts.map(p => seriSorted.filter((val, index) => {
      if (val.short === p) {
        excludeIndexs.push(index);
        return true;
      }
      return false;
    }));
    const otherArr = seriSorted.filter((val, index) => !excludeIndexs.includes(index));

    higherPriorityArr.forEach(arr => {
      results = [...results, ...arr];
    })
    return [
      ...results,
      ...otherArr,
    ];
  };

  const updateQuantity = (quanity, productIndex) => {
    const product = listSearch[productIndex];
    console.log('updateQuantity', quanity, productIndex);
    if (quanity === 0) {
      setCarts(carts.filter(item => item.id !== product.id));
      return;
    }
    const newCarts = carts.slice();
    console.log('product', product);
    const findIndex = newCarts.findIndex(c => c.id === product.id);
    console.log('findIndex', findIndex);
    if (findIndex === -1) {
      newCarts.push({
        ...product,
        quantity: Number(quanity),
        price: Number(quanity) * Number(product.commonPrice),
      })
    } else {
      newCarts[findIndex] = {
        ...newCarts[findIndex],
        quantity: Number(quanity),
        price: Number(quanity) * Number(product.commonPrice),
      }
    }
    setCarts(newCarts);
  };  

  const renderMainContent = () => {
    return (
      <Col flex1 justifyContent='center'>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          {listSearch.length > 0 ? (
            <Grid xs='100%' md='100%' xl='33.33%' p1
              paddingBottom={carts.length > 0 ? 60 : 10}
              middle
              paddingTop={0}
              marginTop={-10}
            >
              {listSearch.map((val, i) => {
                const cartItem = carts.find(item => item.id === val.id);
                console.log('cartItem', cartItem);
                const isInCart = !!cartItem;
                const price = val.price || val.commonPrice;
                const selectedQuantity = cartItem?.quantity || 1;
                return (
                  <Col key={val.id || 'item-'+i} m1 p1 round1 overflow={'hidden'} backgroundColor='white' shadow>
                    <ApolloEffect
                      onPress={() => {
                        showMoneyModal({
                          ...val,
                          price
                        });
                      }}
                    >
                      <RatioCol width={'100%'} ratio={316 / 160} backgroundColor={'rgba(0,0,0,0.3)'}>
                        <Col flex1 middle p1>
                          {val.commonImages && val.commonImages[0] ? (
                            <Image source={{ uri: val.commonImages[0] }} style={{ width: '100%', height: '100%' }} resizeMode='cover' />
                          ) : (
                            <MoneyImage
                              {...val}
                            />
                          )}

                        </Col>
                      </RatioCol>
                    </ApolloEffect>
                    <Row mv1 stretch>
                      <Col flex1>
                        <Row mb1>
                          <Col width={30} height={30} justifyContent='center'>
                            <FontAwesome5 name="money-bill" size={24} color={'rgba(0,0,0,0.3)'} />
                          </Col>
                          <Col ml2 flex1>
                            {val.commonName ? (
                              <Text bold fontSize={14}>{val.commonName}</Text>
                            ) : (
                              <Text bold fontSize={14}>{val.code} {val.seri}</Text>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col width={30} height={30} justifyContent='center'>
                            {/* <MaterialIcons name="attach-money" size={24} color={'rgba(0,0,0,0.3)'} /> */}
                            <Text color={'rgba(0,0,0,0.7)'} fontSize={15}>Giá</Text>
                          </Col>
                          <Col ml2 flex1>
                            <Text bold color="red" fontSize={18}>{VarHelper.formatMoney(price)}</Text>
                          </Col>
                        </Row>
                        {val.commonName && (
                          <Text caption>Tồn kho: {val.commonQuantity || 0}</Text>
                        )}
                      </Col>
                      <Col alignItems={'flex-end'} justifyContent='flex-end'>
                        {/* <Text caption mb1>thêm vào giỏ hàng</Text> */}
                        <TouchField flexDirection={'row'} width={70} height={30} borderRadius={15} middle mb2
                            onPress={() => {
                              showMoneyModal({
                                ...val,
                                price
                              });
                            }}
                          >
                            <Text mr0 caption>Chi tiết</Text>
                            <FontAwesome name="expand" size={16} color={'rgba(0,0,0,0.6)'} />
                          </TouchField>
                        {val.commonName ? (
                          <>
                            {!val.commonQuantity ? (
                              <Col
                                middle height={30}
                                borderRadius={15}
                                width={100}
                                borderThin
                              >
                                <Text>Hết hàng</Text>
                              </Col>
                            ) : (
                              <Row>
                                {isInCart && (
                                  <Row>
                                    <Row>
                                      <TouchField cirle middle
                                        onPress={() => {
                                          updateQuantity(Math.max(0, selectedQuantity - 1), i)
                                        }}
                                      >
                                        <AntDesign name="minus" size={12} color="black" />
                                      </TouchField>
                                      <Input mh0 width={40} value={String(selectedQuantity)} onChange={(newVal) => updateQuantity(newVal, i)} borderWidth={0} />
                                      <TouchField cirle middle
                                        onPress={() => {
                                          updateQuantity(Math.min(+val.commonQuantity, selectedQuantity + 1), i)
                                        }}
                                      >
                                        <AntDesign name="plus" size={12} color="black" />
                                      </TouchField>
                                    </Row>
                                  </Row>
                                  
                                )}
                                <Button
                                  text={isInCart ? 'Đã chọn' : 'Chọn'}
                                  width={100}
                                  height={30}
                                  borderRadius={15}
                                  iconLeft={isInCart ? (
                                    <AntDesign name="check" size={24} color="white" />
                                  ) : (
                                    <EvilIcons name="cart" size={24} color="white" />
                                  )}
                                  backgroundColor={isInCart ? SITE_INFO.BRAND_COLOR : 'red'}
                                  bgHovered={SITE_INFO.BRAND_COLOR}
                                  onPress={() => {
                                    if (isInCart) setCarts(carts.filter(item => item.id !== val.id));
                                    else {
                                      setCarts([...carts, {
                                        ...val,
                                        quantity: 1,
                                        price: Number(val.commonPrice)
                                      }]);
                                    }
                                  }}
                                />
                              </Row>
                            )}
                          </>
                        ) : (
                          <Button
                            text={isInCart ? 'Đã chọn' : 'Chọn'}
                            width={100}
                            height={30}
                            borderRadius={15}
                            iconLeft={isInCart ? (
                              <AntDesign name="check" size={24} color="white" />
                            ) : (
                              <EvilIcons name="cart" size={24} color="white" />
                            )}
                            backgroundColor={isInCart ? SITE_INFO.BRAND_COLOR : 'red'}
                            bgHovered={SITE_INFO.BRAND_COLOR}
                            onPress={() => {
                              if (isInCart) setCarts(carts.filter(item => item.id !== val.id));
                              else {
                                setCarts([...carts, { ...val, price }]);
                              }
                            }}
                          />
                        )}
                      </Col>
                    </Row>

                  </Col>
                );
              })}
            </Grid>
          ) : (
            Boolean(!!message) && (
              <Col p2 middle mb3>
                <Text bold>{message}</Text>
              </Col>
            )
          )}
        </ScrollView>
      </Col>
    )
  };

  return (
    <HomeLayout>
      {renderMainContent()}
    </HomeLayout>
  )
}



CollectionDetail.routeInfo = {
  title: SITE_INFO.NAME,
  path: '/collection/:id/:slug',
};

export default CollectionDetail;