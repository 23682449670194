import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator } from 'react-native';
import Navigation from './src/navigation/Navigation';
import { ModalContainer } from './src/components';
import { SITE_INFO, COLOR } from './src/const';
import './App.css';

function AppWeb() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      await SITE_INFO.onReady();
      setIsLoading(false);
    })();
  }, []);
  return (
    <View style={{ flex: 1 }}>
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator size={'large'} color={COLOR.MAIN} />
        </View>
      ) : (
        <Navigation />
      )}
    </View>
  );
}

export default function App() {
  return (
    <ModalContainer>
      <AppWeb />
    </ModalContainer>
  )
};