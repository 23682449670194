import React, { useState, useEffect } from 'react';
import { Col, Text, CMSLayout, Row, Button } from 'components';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';

import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';

import MoneyList from './AdminMoney.List';
import AddNew from './AdminMoney.AddNew';
import BulkRemove from './AdminMoney.BulkRemove';

const TAB = {
  LIST: 'LIST',
  CREATE: 'CREATE',
  BULK_REMOVE: 'BULK_REMOVE',
  items: [
    { title: 'Danh sách', key: 'LIST' },
    { title: 'Thêm mới', key: 'CREATE' },
    { title: 'Xóa hàng loạt', key: 'BULK_REMOVE' },
  ],
}

const Admin: IScreen = () => {
  const { navigate } = useNavFunc();
  const [curTab, setCurTab] = useState(TAB.LIST);
  const [listQuyUoc, setListQuyUoc] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await Store.Api.QuyUoc.list({});
      if (res.data.success) {
        setListQuyUoc(res.data.data);
      }
    })();
  }, []);

  const renderTab = () => {
    return (
      <Row mb0 height={40} marginLeft={-5}>
        {TAB.items.map((val, i) => (
          <Button
            key={val.key}
            text={val.title}
            outline={val.key !== curTab}
            height={30}
            borderRadius={15}
            width={120}
            m0
            onPress={() => val.key !== curTab && setCurTab(val.key)}
            bgHovered={val.key !== curTab ? COLOR.GREY_BG : undefined}
          />
        ))}
      </Row>
    );
  };

  return (
    <CMSLayout requireAuthen>
      <Col flex1 p2>
        {renderTab()}
        {curTab === TAB.LIST  && (<MoneyList listQuyUoc={listQuyUoc} />)}
        {curTab === TAB.CREATE  && (<AddNew listQuyUoc={listQuyUoc} />)}
        {curTab === TAB.BULK_REMOVE  && (<BulkRemove listQuyUoc={listQuyUoc} />)}
      </Col>
    </CMSLayout>
  )
};

Admin.routeInfo = {
  title: 'Tiền seri',
  path: '/admin/money',
};

export default Admin;